import React, {useCallback, useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  useTheme,
} from '@mui/material';
import {useGb} from '../hooks/gbHooks';
import {SendGlitterForm} from './SendGlitterForm';
import iconFireworks from '../assets/images/icon_fireworks_2x.png';
import {pluralize} from '../util/util';

// "Metapurse" button and associated dialog, which allows you to send a "glitter bomb,"
// i.e. send glitter (change tokens) from one GB id to another
export const Metapurse: React.FC<{gbId: number}> = ({gbId}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = useCallback(() => setDialogOpen(false), []);

  const theme = useTheme();
  const sx = theme.gbComponents.MeetPage.GbCard.GbCardMetadata.Metapurse;

  const {gb} = useGb(gbId);
  if (gb == null) {
    return <></>;
  }

  const {data} = gb;
  const changeCredits = data?.changeCredits;

  return (
    <Box display="inline-flex" flexDirection="column" alignItems="center">
      <Button
        variant="contained"
        color="primary"
        sx={sx.glitterIconButton}
        onClick={() => setDialogOpen(true)}>
        <img src={iconFireworks} alt="Glitter!" />
        Metapurse
      </Button>
      {changeCredits != null && (
        <Typography variant="body2">
          {pluralize('Glitter Credit', changeCredits)}
        </Typography>
      )}
      <Dialog
        onClose={closeDialog}
        maxWidth="md"
        fullWidth
        sx={sx.dialogMetapurse}
        open={dialogOpen}>
        <DialogContent>
          <Typography variant="h4">GB #{gbId}'s Metapurse</Typography>
          {/*<Typography variant="h3" gutterBottom>*/}
          {/*  Send a Glitter Bomb*/}
          {/*</Typography>*/}
          <DialogContentText>
            This Metapurse holds{' '}
            <strong>
              {pluralize('Glitter Credit', gb.data?.changeCredits || 0)}
            </strong>
            !
          </DialogContentText>
          <DialogContentText>
            You can use these credits to reveal new versions of GB #{gbId}.
          </DialogContentText>
          <DialogContentText>
            You can also send a <strong>Glitter Bomb</strong> &ndash; a Glitter
            Credit donation &ndash; to another GB*:
          </DialogContentText>
          {/*TODO: better state if you have 0 credits*/}
          <SendGlitterForm gbId={gbId} onSuccess={closeDialog} />
          <Typography variant={'subtitle2'}>
            * You can Glitter Bomb any GB, including GBs you don't own! Note:
            this is a blockchain interaction, so there will be a small
            transaction fee.
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
