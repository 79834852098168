import gbTeamGlitchyBitches from '../assets/images/team/glitchybitches.png';
import {ExternalLink} from '../components/ExternalLink';
import {gbUrls} from './gbUrls';
// import gbTeamArran from '../assets/images/team/arran.jpg';
import gbTeamArran from '../assets/images/team/arran_moonbird_991.png';
// import gbTeamHarri from '../assets/images/team/harri.png';
import gbTeamHarri from '../assets/images/team/harri_moonbird_8862.png';
// import gbTeamNathan from '../assets/images/team/nathan.jpg';
import gbTeamNathan from '../assets/images/team/nathan_moonbird_9998.png';
import gbTeamAltay from '../assets/images/team/altay.jpg';
import React from 'react';

interface TeamMember {
  name: string;
  src: string; // profile image url
  role: string | JSX.Element;
  contact?: JSX.Element;
}
type TeamMemberKeys = 'glitchyBitches' | 'arran' | 'harri' | 'nathan' | 'altay';

export const teamMembers: {[key in TeamMemberKeys]: TeamMember} = {
  glitchyBitches: {
    src: gbTeamGlitchyBitches,
    name: 'GlitchyBitches',
    role: 'Artist, designer, educator, creator of The GBs',
    contact: <ExternalLink href={gbUrls.twitter}>@glitchybitches</ExternalLink>,
  },
  arran: {
    src: gbTeamArran,
    name: 'Divergence Arran',
    role: (
      <>
        GB Solidity Engineer
        <br /> VP Engineering at Proof.xyz
      </>
    ),
    contact: <ExternalLink href={gbUrls.arran}>@divergencearran</ExternalLink>,
  },
  harri: {
    name: 'Divergence Harri',
    src: gbTeamHarri,
    role: (
      <>
        GB Product
        <br />
        Director of Product at Proof.xyz.
      </>
    ),
    contact: <ExternalLink href={gbUrls.harri}>@divergenceharri</ExternalLink>,
  },
  nathan: {
    name: 'Nathan',
    src: gbTeamNathan,
    role: (
      <>
        GB Python Engineer
        <br /> Biz Dev
      </>
    ),
    contact: <>glitchy.eth</>,
  },
  altay: {
    name: 'Altay',
    src: gbTeamAltay,
    role: (
      <>
        GB Front-End Engineer
        <br />
        Fluorescence Photographer
      </>
    ),
    contact: (
      <>
        {/* <ExternalLink href={gbUrls.harri}>@divergenceharri</ExternalLink> */}
      </>
    ),
  },
};
