import React, {useCallback, useMemo} from 'react';

import gbLogo from '../assets/images/logo_gb_2x.png';
import twitterLogo from '../assets/images/logo_twitter_2x.png';
import discordLogo from '../assets/images/logo_discord_2x.png';

import {
  LinkProps as RouterLinkProps,
  Link as RouterLink,
} from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  Grid,
  Tabs,
  Tab,
  IconButton,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Button,
  Alert,
  Tooltip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import {AccountTab, ConnectWalletButton} from './AccountTab';
import {ExternalLink} from './ExternalLink';
import {gbUrls} from '../util/gbUrls';
import {CONTRACT_CHAIN} from '../util/constants';
import {capitalize} from '../util/util';

export const NavBar: React.FC = () => {
  const theme = useTheme();

  // drawer is the mobile sidebar menu
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const [tabIndex, setTabIndex] = React.useState(0);
  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabIndex(newValue);
    },
    []
  );

  type GbTab = [
    React.ReactElement,
    string,
    typeof ExternalLink | typeof RouterLink
  ];
  const tabs: GbTab[] = useMemo(() => {
    const baseTabs: GbTab[] = [
      [<>Mint</>, gbUrls.home, RouterLink],
      // ['Meet & Modify', gbUrls.meet, RouterLink],
      [<>Vision</>, gbUrls.vision, RouterLink],
      [<>FAQ</>, gbUrls.faq, RouterLink],
      [<>MOTH</>, gbUrls.moth, ExternalLink],
      [
        <Box>
          <img src={twitterLogo} alt="Twitter" />
          Twitter
        </Box>,
        gbUrls.twitter,
        ExternalLink,
      ],
      [
        <Box>
          <img src={discordLogo} alt="Twitter" />
          Discord
        </Box>,
        gbUrls.discord,
        ExternalLink,
      ],
    ];

    // if (!mintingEnabled) {
    //   return baseTabs;
    // }
    return [
      ...baseTabs,
      [<>OpenSea</>, gbUrls.opensea, ExternalLink], // TODO: actual link
    ];
  }, []);

  return (
    <>
      <AppBar sx={theme.gbComponents.AppBar.root}>
        <Toolbar>
          <Grid container height="70px" flexWrap="nowrap" columnSpacing={2}>
            {/* Logo */}
            <Grid item sx={theme.gbComponents.AppBar.logo}>
              <RouterLink to={gbUrls.home} onClick={() => setTabIndex(0)}>
                <img src={gbLogo} alt="GB Logo" />
              </RouterLink>
            </Grid>

            {/* Tabs */}
            <Grid item flex={1}>
              <Tabs
                TabIndicatorProps={{children: <span />}} // add a <span> for indicator styling
                value={tabIndex}
                onChange={handleTabChange}
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'flex',
                  },
                }}
                aria-label="GB Navigation Tabs">
                {tabs.map((tab, i) => {
                  return (
                    <Tab
                      key={i}
                      LinkComponent={tab[2]}
                      label={tab[0]}
                      // so annoying!
                      {...(tab[2] === RouterLink
                        ? {to: tab[1]}
                        : {href: tab[1]})}
                      sx={theme.gbComponents.AppBar.Tab}
                    />
                  );
                })}
                <AccountTab
                  noMetamaskComponent={
                    <Box
                      sx={{
                        marginLeft: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <Button
                        variant="contained"
                        href={gbUrls.metamaskInstall}
                        target="_blank"
                        rel="noopener noreferrer">
                        Install MetaMask
                      </Button>
                    </Box>
                  }
                  wrongChainComponent={<WrongChainComponent />}
                  loadingComponent={
                    <Box
                      sx={{
                        marginLeft: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      Connecting...
                    </Box>
                  }
                  connectedComponent={
                    <Tab
                      key={tabs.length}
                      component={RouterLink}
                      label="Your Muses"
                      to={gbUrls.meet}
                      value={tabs.length}
                      sx={{
                        ...theme.gbComponents.AppBar.Tab,
                        marginLeft: 'auto',
                      }}
                      onClick={(e: React.SyntheticEvent) =>
                        handleTabChange(e, tabs.length)
                      }
                    />
                  }
                  disconnectedComponent={
                    <Box
                      sx={{
                        marginLeft: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <ConnectWalletButton />
                    </Box>
                  }
                />
              </Tabs>
            </Grid>
            {/* Hamburger menu icon */}
            <Grid
              item
              sx={{
                ...theme.gbComponents.AppBar.hamburger,
                display: {
                  xs: 'flex',
                  sm: 'flex',
                  md: 'flex',
                  lg: 'none',
                },
              }}>
              <IconButton
                size="large"
                onClick={() => setDrawerOpen(!drawerOpen)}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={theme.gbComponents.Drawer.root}>
        <Box
          sx={{width: '250px', marginTop: '70px'}}
          role="presentation"
          onClick={() => setDrawerOpen(false)}>
          <List>
            {tabs.map((tab, i) => (
              <ListItemLink
                key={i}
                to={tab[1]}
                primary={tab[0]}
                LinkComponent={tab[2]}
                selected={i === tabIndex}
                onClick={(e) => handleTabChange(e, i)}
              />
            ))}
            <AccountTab
              noMetamaskComponent={
                <ListItem>
                  <Button
                    variant="contained"
                    href={gbUrls.metamaskInstall}
                    target="_blank"
                    sx={{margin: '0 auto'}}
                    rel="noopener noreferrer">
                    Install MetaMask
                  </Button>
                </ListItem>
              }
              loadingComponent={
                <ListItem sx={{justifyContent: 'center'}}>
                  Connecting...
                </ListItem>
              }
              wrongChainComponent={
                <ListItem sx={{justifyContent: 'center'}}>
                  <Alert variant={'filled'} severity={'error'}>
                    Wrong chain! <br />
                    Please switch to {capitalize(CONTRACT_CHAIN)}
                  </Alert>
                </ListItem>
              }
              connectedComponent={
                <ListItemLink
                  to={gbUrls.meet}
                  primary={'Your Muses'}
                  selected={tabs.length === tabIndex}
                  onClick={(e) => handleTabChange(e, tabs.length)}
                />
              }
              disconnectedComponent={
                <ListItem sx={{justifyContent: 'center'}}>
                  <ConnectWalletButton />
                </ListItem>
              }
            />
          </List>
        </Box>
      </Drawer>
    </>
  );
};

// See https://mui.com/guides/composition/
const ListItemLink: React.FC<{
  to: string;
  primary: React.ReactNode;
  LinkComponent?: typeof RouterLink | typeof ExternalLink;
  selected?: boolean;
  onClick(e: React.SyntheticEvent): void;
}> = ({to, primary, selected, LinkComponent, onClick}) => {
  const CustomLink = React.useMemo(() => {
    if (LinkComponent === ExternalLink) {
      return React.forwardRef<
        HTMLAnchorElement,
        Omit<typeof ExternalLink, 'href'>
      >(function Link(linkProps, ref) {
        return <ExternalLink ref={ref} href={to} {...linkProps} />;
      });
    }
    return React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(
      function Link(linkProps, ref) {
        return <RouterLink ref={ref} to={to} {...linkProps} />;
      }
    );
  }, [LinkComponent, to]);

  return (
    <ListItem
      button
      component={CustomLink}
      selected={selected}
      onClick={onClick}
      sx={{textAlign: 'center'}}>
      <ListItemText primary={primary} />
    </ListItem>
  );
};

const WrongChainComponent: React.FC = () => {
  return (
    <>
      <Tooltip arrow title={`Please switch to ${capitalize(CONTRACT_CHAIN)}!`}>
        <Box
          sx={{
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
          }}>
          <Alert variant="filled" severity="error">
            Wrong chain!
          </Alert>
        </Box>
      </Tooltip>
    </>
  );
};
