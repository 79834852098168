import {Box, Chip, Grid, Typography, useTheme} from '@mui/material';
import React, {useMemo} from 'react';
import {useGb} from '../hooks/gbHooks';

export const GbCardAttributes: React.FC<{
  gbId: number;
  selectedVersion: number; // the currently-selected version (not necessarily the live version)
}> = ({gbId, selectedVersion}) => {
  const theme = useTheme();
  const sx = theme.gbComponents.MeetPage.GbCard.GBCardAttributes;

  const {gb} = useGb(gbId);
  const attributes = useMemo(
    () => gb?.data?.images[selectedVersion]?.attributes,
    [gb?.data?.images, selectedVersion]
  );

  if (gb == null) {
    return <></>;
  }

  return (
    <Grid container item sx={sx.root} direction="column">
      <Typography variant="h6">
        Version #{selectedVersion + 1} Attributes:
      </Typography>
      <Box>
        {attributes == null ? (
          <Typography variant="body2">Still a mystery!</Typography>
        ) : (
          Object.entries(attributes).map(([k, v], i) => {
            return (
              <Chip
                key={i}
                sx={sx.attributeChip}
                label={
                  <>
                    <strong style={{textTransform: 'uppercase'}}>{k}</strong>:{' '}
                    {v}
                  </>
                }
                color="primary"
                variant="outlined"
              />
            );
          })
        )}
      </Box>
    </Grid>
  );
};
