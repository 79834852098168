import React, {useContext, useMemo} from 'react';
import {useContract} from '../hooks/gbHooks';
import {contractAddresses, glitchyAbi, metapurseAbi} from '../contracts/gbAbi';
import {CONTRACT_CHAIN} from '../util/constants';
import {ContractContext as GlitchyBitchesContractContext} from '../types/generated/abi-glitchybitches-0.8.10+commit.fc410830.Linux.g++';
import {ContractContext as MetapurseContractContext} from '../types/generated/abi-metapurse-0.8.10+commit.fc410830.Linux.g++';

type GbContractContextState = {
  glitchyBitchesContract: GlitchyBitchesContractContext | null;
  metapurseContract: MetapurseContractContext | null;
};

export const GbContractContext = React.createContext<GbContractContextState>({
  glitchyBitchesContract: null,
  metapurseContract: null,
});

// We use two different contracts (GlitchyBitches and Metapurse).
// This loads both of them into context.
export const GbContractContextProvider: React.FC = ({children}) => {
  // Load the GlitchyBitches contract
  const {contract: glitchyBitchesContract} =
    useContract<GlitchyBitchesContractContext>({
      contractAddress: contractAddresses[CONTRACT_CHAIN].primary,
      abi: glitchyAbi,
    });

  // Load the Metapurse contract
  const {contract: metapurseContract} = useContract<MetapurseContractContext>({
    contractAddress: contractAddresses[CONTRACT_CHAIN].metapurse,
    abi: metapurseAbi,
  });

  const state = useMemo(
    () => ({
      glitchyBitchesContract,
      metapurseContract,
    }),
    [metapurseContract, glitchyBitchesContract]
  );

  return (
    <GbContractContext.Provider value={state}>
      {children}
    </GbContractContext.Provider>
  );
};

export const useGbContract = () => {
  return useContext(GbContractContext);
};
