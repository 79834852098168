import React, {useMemo} from 'react';
import {Grid, Typography} from '@mui/material';
import {GB_MINT_PRICE} from '../util/constants';

export const TermsAndConditionsPage: React.FC = () => {
  const terms: [React.ReactElement, Array<React.ReactElement>][] = useMemo(
    () => [
      [
        <>About the Glitchy Bitches</>,
        [
          <>
            Glitchy Bitches are created and sold by Aerie Inc, a company based
            in Massachusetts, USA, under the terms and conditions outlined on
            this page.
          </>,
          <>
            The Glitchy Bitches are a set of 10,101 digital artworks
            (Non-Fungible Tokens, or “NFTs”) that operate on the Ethereum
            blockchain. An individual token is referred to here as a “GB”. This
            website, https://glitchybitches.com, provides a user interface for
            participants to purchase NFTs, as well as to interact with any NFTs
            they own. During the sales period, individuals will be able to
            purchase GBs on this website at a price of {GB_MINT_PRICE} ETH each
            using an electronic Ethereum wallet. Transactions made via the
            website and associated Ethereum smart contracts are managed and
            confirmed via the Ethereum blockchain. We do not cover the
            transaction fee of the Ethereum network (“Gas Fee”) and will not be
            liable for the acts or omissions of any third parties, nor will we
            be liable for any damage that you may suffer as a result of your
            transactions or any other interaction with any third parties
            including MetaMask, web browsers such as Google Chrome, and the
            Ethereum network. The Glitchy Bitches website and related services
            are provided “as is” and “as available” without any type of
            warranty. Participants acknowledge that, given that the Glitchy
            Bitches smart contract operates on the Ethereum network, there is no
            mechanism to undo, reverse, or reinstate any transactions.
          </>,
          <>
            GB NFTs have six versions that can be revealed over time. The actual
            GBs are too large to be stored on the blockchain, so we took a hash
            of the composite image of all GB NFTs and embedded it into the Smart
            Contract. Initially all data will be hosted on a private secure
            server to prevent the secrets of “unrevealed” GBs from being
            discovered; 6 months after first mint (or the last GB version is
            uncovered, whichever is sooner) we will transfer all data to IPFS.
            We will support such storage until at least December 31, 2031.
          </>,
        ],
      ],
      [
        <>Your responsibilities</>,
        [
          <>
            By using the website and related services, you accept sole
            responsibility for:
          </>,
          <ul>
            <li>All transactions involving Glitchy Bitches collectables</li>
            <li>
              The safety and maintenance of your Ethereum wallet and the private
              key to access it
            </li>
            <li>
              Checking the validity of transactions and contracts from this
              website prior to approval
            </li>
            <li>
              Downloading your NFT Art on or before December 31, 2031, after
              which we may no longer support the storage of the actual NFT Art.
            </li>
            <li>
              Determining what, if any, taxes apply to any transactions related
              to the Glitchy Bitches project, which may vary for different
              owners based on their place of residence for tax purposes
            </li>
            <li>Paying any taxes due to the relevant authorities</li>
          </ul>,
        ],
      ],
      [
        <>Participant Ownership and Rights</>,
        [
          <ol>
            <li>
              <strong>Participants own their NFTs.</strong> Each GB is a
              non-fungible token on the Ethereum network. Token ownership is
              completely managed by our smart contract and the Ethereum
              blockchain. We are unable to take over, freeze, or change the
              ownership of any of the GBs.
            </li>
            <li>
              <strong>Personal Use.</strong> As long as you comply with these
              terms and conditions, Aerie Inc. grants you a worldwide,
              royalty-free license to use, copy, and display the purchased Art,
              as well as any extensions of it that you create, solely for the
              following purposes:
              <ol type="i">
                <li>for your personal, non-commercial use;</li>
                <li>
                  as part of a marketplace that allows the buying and selling of
                  your Glitchy Bitches NFT, as long as the marketplace
                  cryptographically verifies each NFT owner’s rights to display
                  the Art to ensure that only the actual owner can display the
                  Art; or{' '}
                </li>
                <li>
                  as part of a third party website or application that allows
                  the inclusion, involvement, or participation of your Glitchy
                  Bitch NFT, so long as that website or application
                  cryptographically verifies each participant’s rights to
                  display the Art for their Glitchy Bitches NFT to ensure that
                  only the actual owner can display the Art, and provided that
                  the Art is no longer visible once the owner of the Glitchy
                  Bitches NFT leaves the website or application.
                </li>
              </ol>
            </li>
            <li>
              <strong>Commercial Use.</strong> As long as you comply with these
              terms and conditions, Aerie Inc grants you a worldwide,
              royalty-free license to use, copy, and display the purchased Art
              for the purpose of creating derivative works based on the Art
              (“Commercial Use”). Examples include producing merchandise
              products like T-shirts and displaying copies of the Art. These
              terms, for example, permit you to own or operate a for-profit
              marketplace or third party website or application for the use,
              sale, inclusion, or participation of Glitchy Bitches, so long as
              that marketplace, website, or application cryptographically
              verifies each Glitchy Bitches NFT participant’s rights to display
              the Art for their Glitchy Bitch NFT to ensure that only the actual
              owner can display the Art, and provided that the Art is no longer
              visible once the owner of the Glitchy Bitches NFT leaves the
              website or application.
            </li>
            <li>
              <strong>Restrictions.</strong> You agree that you may not, nor
              permit any third party to do or attempt to do any of the foregoing
              without Aerie Inc’s express prior written consent in each case:
              <ol type="i">
                <li>
                  use the purchased Art to advertise, market, or sell any third
                  party product or service;
                </li>
                <li>
                  use the purchasers Art in connection with images, videos, or
                  other forms of media that depict hatred, intolerance,
                  violence, cruelty, or anything else that could reasonably be
                  found to constitute hate speech or otherwise infringe upon the
                  rights of others;
                </li>
                <li>
                  sell, distribute for commercial gain (including, without
                  limitation, giving away in the hopes of eventual commercial
                  gain), or otherwise commercialize merchandise that includes,
                  contains, or consists of the purchased Art, except as
                  expressly permitted in these Terms;
                </li>
                <li>
                  attempt to trademark, copyright, or otherwise acquire
                  additional intellectual property rights in or to the purchased
                  Art.
                </li>
              </ol>
            </li>
          </ol>,
        ],
      ],
      [
        <>Creator Rights</>,
        [
          <>
            Aerie Inc shall retain the rights to, including but not limited to,
            reproduce, adapt, modify, use, perform, display, publish,
            distribute, sell and duplicate the purchased Art and any derivative
            works thereof, in whole or in part.
          </>,
        ],
      ],
      [
        <>Value</>,
        [
          <>
            GBs are sold at their assumed market value at the time of sale, with
            no expectation of future increases in value. The prices of
            collectible blockchain assets and cryptocurrencies are extremely
            volatile and subjective, and individual assets such as Glitchy
            Bitches NFTs have no inherent or intrinsic value. Further risk is
            introduced by the regulatory uncertainty which surrounds crypto
            assets including NFTs and cryptocurrencies, changes in which could
            significantly impact the utility and value of the Ethereum
            blockchain and the ecosystem built around it. By purchasing a GB,
            you accept these risks and acknowledge that your GB may not retain
            its original value.
          </>,
        ],
      ],
    ],
    []
  );

  return (
    <Grid container direction="column" rowSpacing={8}>
      <Grid item>
        <Typography variant="h2" gutterBottom>
          Terms & Conditions
        </Typography>
        {/* <Typography variant="gbText" gutterBottom>
          Details details...
        </Typography>
        <Divider /> */}
        {terms.map(([title, section], i) => (
          <React.Fragment key={i}>
            <Typography variant="h6">{title}</Typography>
            {section.map((element, j) => (
              <Typography key={j} variant="gbText" component={'div'}>
                {element}
              </Typography>
            ))}
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};
