import React, {useCallback, useContext, useMemo, useState} from 'react';
import produce from 'immer';
import {GB, GBJson} from '../types/gbTypes';
import {useGbContract} from './GbContractContextProvider';
import {fetchJson} from '../util/util';
import {useGbContractMethod} from '../hooks/gbHooks';

type GbDataContextState = {
  allGbs: GB[]; // holds all the data for all loaded GBs
  fetchGbById(gbId: number): void; // fetch GB data from contract (asynchronous)
  findGbById(gbId: number): GB | undefined; // look up data in allGbs
};

export const GbDataContext = React.createContext<GbDataContextState>({
  allGbs: [],
  fetchGbById: () => false,
  findGbById: () => undefined,
});

export const useGbData = () => {
  return useContext(GbDataContext);
};

// This context holds the data for all GBs.
// Also provides methods for fetching data for a single GB,
// and for looking up data in the allGbs array.
export const GbDataContextProvider: React.FC = ({children}) => {
  const [allGbs, setAllGbs] = useState<GB[]>([]);

  const {glitchyBitchesContract} = useGbContract();

  const setLoading = useCallback((gbId: number, isLoading: boolean) => {
    setAllGbs(
      produce(allGbsDraft => {
        const gb = allGbsDraft.find(gb => gb.id === gbId);
        // If gb doesn't yet exist in allGbs, create it
        if (gb == null) {
          allGbsDraft.push({id: gbId, data: null, loading: isLoading});
          return;
        }
        gb.loading = isLoading;
      })
    );
  }, []);

  const setData = useCallback((gbId: number, gbJson: GBJson) => {
    setAllGbs(
      produce(allGbsDraft => {
        const gb = allGbsDraft.find(gb => gb.id === gbId);
        if (gb == null) {
          throw new Error(`setData: Can't find GB with id ${gbId}`);
        }

        gb.data = {
          changeCredits: gbJson.collapsed.change_credits,
          currentVersion: gbJson.collapsed.version,
          revealedVersion: gbJson.collapsed.revealed,
          images: gbJson.collapsed.revealed_images.map((url, i) => ({
            src: url,
            attributes: gbJson.collapsed.revealed_metadata[i],
          })),
        };
        gb.loading = false;
      })
    );
  }, []);

  // TODO: error handling
  const {method: getTokenUri} = useGbContractMethod('tokenURI'); //, error: getTokenUriError} =

  // Asynchronously fetch gb json data from contract
  const fetchGbById = useCallback(
    async (gbId: number) => {
      console.log('fetchById | gb', gbId);
      setLoading(gbId, true);

      if (
        glitchyBitchesContract == null ||
        glitchyBitchesContract.provider == null
      ) {
        // TODO: error handling
        setLoading(gbId, false);
        return;
      }
      try {
        // TODO: why tf do I have to do 'as string'? typescript should know it's a string
        const tokenJsonUri = (await getTokenUri({variables: [gbId]})) as string;
        const gbJson: GBJson = await fetchJson<GBJson>(tokenJsonUri);
        setData(gbId, gbJson);
      } catch (e) {
        // TODO: better error handling
        throw e;
      }
    },
    [getTokenUri, glitchyBitchesContract, setData, setLoading]
  );

  // Synchronously look up GB in already-loaded data
  const findGbById = useCallback(
    (gbId: number) => {
      return allGbs.find(gb => gb.id === gbId);
    },
    [allGbs]
  );

  const state = useMemo(
    () => ({
      allGbs,
      findGbById,
      fetchGbById,
    }),
    [allGbs, fetchGbById, findGbById]
  );

  return (
    <GbDataContext.Provider value={state}>{children}</GbDataContext.Provider>
  );
};
