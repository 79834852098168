import {ethers} from 'ethers';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useGbContract} from '../components/GbContractContextProvider';
import {useGbData} from '../components/GbDataContextProvider';
import {TransactionState} from '../types/gbTypes';
import {AbiGlitchybitches} from '../types/generated/abi-glitchybitches-0.8.10+commit.fc410830.Linux.g++';
import {AbiMetapurse} from '../types/generated/abi-metapurse-0.8.10+commit.fc410830.Linux.g++';
import {getErrorMessage} from '../util/errors';
import {useMetamaskContext} from '../components/MetamaskProvider';

export function useWeb3Provider() {
  const [provider, setProvider] = useState<
    ethers.providers.Web3Provider | undefined
  >(undefined);
  const initializeProvider = useCallback(async () => {
    if (window.ethereum == null) {
      return undefined;
    }
    try {
      const prov = await new ethers.providers.Web3Provider(window.ethereum);
      setProvider(prov);
    } catch (e) {
      // TODO: error handling
      console.log('initializeProvider error:', e);
    }
  }, []);

  useEffect(() => {
    (async () => await initializeProvider())();
  }, [initializeProvider]);

  return useMemo(() => ({provider}), [provider]);
}

// Returns an ethers.js Contract object, with current metamask account as the Signer
export function useContract<T>({
  contractAddress,
  abi,
}: {
  contractAddress: string;
  abi: any; // TODO: type?
}) {
  const {provider} = useWeb3Provider();
  const [contract, setContract] = useState<T | null>(null);

  const fetchContract = useCallback(async () => {
    if (provider == null) {
      setContract(null);
      return;
    }
    const signer = provider.getSigner();
    const ethersContract = (await new ethers.Contract(
      contractAddress,
      abi,
      signer //provider
    )) as unknown as T;
    // console.log('Loaded contract', contractAddress, ethersContract);
    setContract(ethersContract);
  }, [abi, contractAddress, provider]);

  useEffect(() => {
    (async () => await fetchContract())();
  }, [fetchContract]);

  return useMemo(() => ({contract}), [contract]);
}

// Narrow contract type to methods we're actually using
type AbiGlitchybitchesAvailableMethods = Pick<
  AbiGlitchybitches,
  'safeMint' | 'tokenURI' | 'balanceOf' | 'tokenOfOwnerByIndex' | 'totalSupply'
>;
type AbiMetapurseAvailableMethods = Pick<
  AbiMetapurse,
  'donate' | 'changeToVersion' | 'revealVersion'
>;

// Give this the string of a methodName, it'll find the method on the contract and return it,
// along with a status string and an error, which get updated when you call the method.
export function useGbContractMethod<
  K extends keyof (AbiGlitchybitchesAvailableMethods &
    AbiMetapurseAvailableMethods)
>(methodName: K) {
  type MethodSignature = K extends keyof AbiGlitchybitches
    ? AbiGlitchybitches[K]
    : K extends keyof AbiMetapurse
    ? AbiMetapurse[K]
    : null;

  // TODO: why doesn't this actually constrain the output type of method?
  type MethodReturn = ReturnType<MethodSignature>;

  // Annoying we have to type 'this' to call .apply, but typescript complains otherwise.
  // Got the idea from here: https://stackoverflow.com/questions/65245161/typescript-error-the-this-context-of-type-is-not-assignable-to-methods
  // There's probably a smarter way to do this...
  type MethodSignatureWithThis = (
    this: null,
    ...args: Parameters<MethodSignature> | []
  ) => MethodReturn; //ReturnType<MethodSignature>;

  const {fetchGbById} = useGbData();
  const {glitchyBitchesContract, metapurseContract} = useGbContract();

  const [status, setStatus] = useState<TransactionState>(null);
  const [error, setError] = useState<string | null>(null);

  // Look up the method among the contracts
  // TODO: currently this does the lookup for every component that uses it.
  // Might be better to just do the lookup once in context.
  // (Downside: shared status/error context unless you maintain a state object)
  const contractMethod: null | MethodSignatureWithThis = useMemo(() => {
    // console.log('FINDING METHOD', methodName);

    if (glitchyBitchesContract?.hasOwnProperty(methodName)) {
      return glitchyBitchesContract[
        // TODO: why does typescript need this hint?
        methodName as keyof AbiGlitchybitchesAvailableMethods
      ] as MethodSignatureWithThis;
    }
    if (metapurseContract?.hasOwnProperty(methodName)) {
      return metapurseContract[
        // TODO: why does typescript need this hint?
        methodName as keyof AbiMetapurseAvailableMethods
      ] as MethodSignatureWithThis;
    }
    return null;
  }, [glitchyBitchesContract, metapurseContract, methodName]);

  const {error: metamaskError} = useMetamaskContext();

  // Exported from hook, use this to actually call the method.
  // It updates status and error state when the method is called.
  const method =
    // : (args: {
    //     variables: Parameters<MethodSignature>;
    //     refetchGbIds?: number[];
    //   }) => MethodReturn = // Promise<null | string | ContractTransaction | ContractReceipt> =
    useCallback(
      async (args?: {
        variables?: Parameters<MethodSignature>;
        refetchGbIds?: number[];
      }) => {
        if (metamaskError === 'WRONG_CHAIN') {
          console.log(
            `ERROR: contract method ${methodName} called on wrong chain`
          );
          return null; // TODO: make this more useful
        }
        if (contractMethod == null) {
          console.log(`ERROR: contract method ${methodName} not found`);
          return null; // TODO: make this more useful
        }
        try {
          const {variables = [], refetchGbIds} = args || {};
          console.log(
            `${methodName} | Dispatch:`,
            'variables:',
            variables,
            'refetchGbIds:',
            refetchGbIds
          );
          console.log(`${methodName} | Set status: 'loading'`);
          // TODO: change 'loading' to 'confirming' or something?
          setStatus('loading');
          const methodResponse = await contractMethod.apply(null, variables);
          console.log(`${methodName} | methodResponse`, methodResponse);
          // Transactions, which have a 'wait' method on the initial response, take place in two steps:
          // 1) checkout with metamask (status: 'loading')
          // 2) wait for transaction to complete (status: 'waiting')
          let waitResponse: ethers.ContractReceipt | null = null;
          if (
            typeof methodResponse !== 'string' &&
            typeof methodResponse !== 'number' &&
            // typeof methodResponse !== 'boolean' &&
            'wait' in methodResponse
          ) {
            console.log(`${methodName} | Set status: 'waiting'`);
            setStatus('waiting');
            waitResponse = await methodResponse.wait();
            console.log(`${methodName} | waitResponse`, waitResponse);
          }
          if (refetchGbIds != null) {
            await Promise.all(
              refetchGbIds.map(async id => {
                // console.log('Refetching gb#', id);
                return fetchGbById(id);
              })
            );
          }
          console.log(`${methodName} | Set status: null`);
          setStatus(null);
          // TODO: why doesn't MethodReturn actually narrow the type?
          return (waitResponse ?? methodResponse) as MethodReturn;
        } catch (e) {
          setStatus('error');
          console.log(`%c${methodName} | Set status: 'error'`, 'color:red');
          setError(getErrorMessage(e));
          console.log(e);
          return null;
        }
      },
      [metamaskError, contractMethod, methodName, fetchGbById]
    );

  const clearError = useCallback(() => {
    setError(null);
    setStatus(null);
  }, []);

  // Helpers, so you can do if (waiting) instead of if (status === 'waiting')
  const ready = useMemo(() => status === null, [status]);
  const loading = useMemo(() => status === 'loading', [status]);
  const waiting = useMemo(() => status === 'waiting', [status]);
  const complete = useMemo(() => status === 'complete', [status]);

  return useMemo(
    () => ({
      method,
      status,
      error,
      ready,
      loading,
      waiting,
      complete,
      clearError,
    }),
    [method, status, error, ready, loading, waiting, complete, clearError]
  );
}

// TODO: is this still necessary? should contract methods live here?
export function useGb(gbId?: number) {
  const {findGbById} = useGbData();
  const gb = useMemo(
    () => (gbId == null ? null : findGbById(gbId)),
    [findGbById, gbId]
  );

  return useMemo(
    () => ({
      gb,
    }),
    [gb]
  );
}

// Use previous value of a prop
// See https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export function usePrevious<T>(
  value: T
): MutableRefObject<T | undefined>['current'] {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
