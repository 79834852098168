import React, {useMemo} from 'react';
import {Grid, Typography} from '@mui/material';
import {ExternalLink} from './ExternalLink';
import {gbUrls} from '../util/gbUrls';
import {teamMembers} from '../util/teamMembers';

export const FaqPage: React.FC = () => {
  const faq: [React.ReactElement, React.ReactElement[]][] = useMemo(
    () => [
      [
        <>The GBs change? What does that even mean? How?</>,
        [
          <>
            The GBs take the form of multi-image NFTs, which will slowly reveal
            themselves over time in response to their hodler's requests.
          </>,
          <>
            Each GB has six versions, which you can reveal by accruing and
            spending your Glitter Credits.
          </>,
          <>
            It costs 30 Glitter Credits to reveal a new version of a GB, and 10
            Glitter Credits to change back to a previous version. But be warned
            — a GB is a feisty creatures, and there's a chance that she might
            have other plans!
          </>,
        ],
      ],
      [
        <>Wait, what are "Glitter Credits"?</>,
        [
          <>
            Glitter Credits are the in-app currency used to evolve your GBs.
            Each GB stores her Glitter in her Metapurse.
          </>,
          <>Most GBs earn one Glitter Credit per day.</>,
          <>
            (However, there are a lot of surprises hidden in this collection,
            and one of them is that some GBs have "Glitter Bomber" powers,
            because they accrue Glitter faster than others!)
          </>,
          <>
            Note: Glitter Credits are simply a dynamic property of each GB, and
            are stored on-chain (i.e. Glitter is not an ERC20), so Glitter
            Credits have no financial value and cannot be split off for trading.
          </>,
        ],
      ],
      [
        <>
          Is there any way to increase the amount of Glitter in my Metapurse,
          other than by accruing the daily credits?{' '}
        </>,
        [
          <>Yes!</>,
          <ul>
            <li>
              If you purchase your GB during the initial minting window, you'll
              get a bonus 30 Glitter Credits on day 1, meaning you can jump
              right in and reveal your second image if you want. But be warned:
              you won't be able to change it back until you earn another 10
              credits (10 days), so you might want to wait...
            </li>
            <li>
              Any GB can "send" her Glitter credits to another GB, via her
              Metapurse. You can move Glitter between your own GBs, or send a
              Glitter Bomb to any other GB.
            </li>
            <li>
              Finally, the admins have the ability to shower GBs with Glitter,
              which we'll use from time to time to recognize community
              participation.
            </li>
          </ul>,
        ],
      ],
      [
        <>How can I work out the rarity of my GB?</>,
        [
          <>
            The traits you see listed in OpenSea will relate to the particular
            version of herself your GB is displaying to the world. Percentages
            are based on the full list of traits displayed by GBs at that moment
            in time (albeit a little out of date, as OpenSea doesn't refresh
            this data instantly). We will be adding a traits calculator on this
            website where you can see how rare a particular trait is across all
            GBs, including versions not yet revealed. You will also be able to
            calculate the rarity of your GB so far based on versions already
            revealed... but final rarity scores will slowly evolve over time.
            Patience is a virtue ;)
          </>,
        ],
      ],
      [
        <>
          Surely experienced devs can just look ahead to see what's coming and
          figure out which pieces are the "rarest"?
        </>,
        [
          <>Tee-hee, do you really think we didn't think about that?! :)</>,
          <>
            As each image (and its associated metadata) is "revealed" by its
            holder, the data will be transferred to IPFS (aka the
            "InterPlanetary File System", the peer-to-peer media protocol used
            by the majority of NFTs). In the meantime, data and images for
            "unrevealed" GB versions will be hosted on a private, secure server.
          </>,
        ],
      ],
      [
        <>Can I make derivatives?</>,
        [
          <>
            Please do, but after the launch! The GBs exist to inspire and
            motivate others to be creative, so we would love to see them in new
            and exciting forms—all we ask is that you share them on our Discord.
            We will specify licence details here before minting begins.
          </>,
        ],
      ],
      [
        <>Who are you?</>,
        [
          <>
            The creator of the art and concept is an artist, designer, and
            professor who goes by
            <strong>{teamMembers.glitchyBitches.name}</strong>. Her studio
            projects have received numerous awards, been shown internationally,
            and been featured in publications including Time, Wired, CNN,
            Kinfolk, and Forbes. She began her career in the fashion world,
            working directly for the CEO of the Gucci Group in a leading role
            that shaped the future of sustainable fashion design. She left the
            fashion world to join the faculty of Harvard University, where she
            became a popular design professor while running her studio in
            Boston. She’s interested in what new technologies and their
            associated business models mean for creators of all kinds. Since
            Fall 2021, she leads the MADE Program, a major joint initiative
            between the Rhode Island School of Design (a top arts school in the
            US) and Brown University (the Ivy League alma mater of OpenSea
            co-founder, Devin Finzer). She is also an art advisor to
            Proof/Moonbirds.
          </>,
          <>
            {teamMembers.glitchyBitches.name} is supported by an excellent team:
          </>,
          <ul>
            <li>
              <strong>Harri and Divergence</strong>, the team behind{' '}
              <ExternalLink href={gbUrls.brotchain}>Brotchain</ExternalLink> and{' '}
              <ExternalLink href={gbUrls.poaw}>
                Proof of &#123;Art&#125;Work
              </ExternalLink>
              , are based in London. They both now work full-time at Proof. They
              are the brains behind the morphing portrait contract complexity,
              which was no small feat. Divergence handled the solidity
              development to realize the technical vision and ensure that
              everything works reliably. Harri helped with the solidity
              contract, as a project management whiz, and worked closely with
              {teamMembers.glitchyBitches.name} on community and philanthropic
              efforts.
            </li>
            <li>
              <strong>Nathan</strong> is a Boston-based tech entrepreneur and
              investor who is always interested in new technologies and business
              models. He is also {teamMembers.glitchyBitches.name}’ partner.{' '}
              {teamMembers.glitchyBitches.name} and Nathan are IRL friends with
              the rest of the team.
            </li>
            <li>
              <strong>Altay</strong> is a nomadic engineer and long-time friend
              of {teamMembers.glitchyBitches.name}. He made all the UI magic
              happen for GB.com.
            </li>
          </ul>,
        ],
      ],
    ],
    []
  );

  return (
    <Grid container direction="column" rowSpacing={8}>
      <Grid item>
        {' '}
        {/*} xs={2} md={1} sx={{textAlign: {xs: 'center', md: 'initial'}}}> */}
        <Typography variant="h2" gutterBottom>
          Frequently-Asked Questions
        </Typography>
        {/* <Typography variant="gbText" gutterBottom>
          All the key info you can handle.
        </Typography>
        <Divider /> */}
        {faq.map(([question, answerParagraphs], i) => (
          <React.Fragment key={i}>
            <Typography variant="h6" sx={{fontWeight: 'bold'}}>
              {question}
            </Typography>
            {answerParagraphs.map((p, j) => (
              <Typography key={j} variant="gbText" component={'div'}>
                {p}
              </Typography>
            ))}
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};
