// TODO: look into library that types contracts
export const newGbAbi = {
  contracts: {
    '@divergencetech/ethier/contracts/crypto/SignatureChecker.sol:SignatureChecker':
      {abi: []},
    '@divergencetech/ethier/contracts/erc721/BaseOpenSea.sol:BaseOpenSea': {
      abi: [
        {
          inputs: [],
          name: 'contractURI',
          outputs: [{internalType: 'string', name: '', type: 'string'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'owner', type: 'address'},
            {internalType: 'address', name: 'operator', type: 'address'},
          ],
          name: 'isOwnersOpenSeaProxy',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
      ],
    },
    '@divergencetech/ethier/contracts/erc721/BaseOpenSea.sol:OwnableDelegateProxy':
      {abi: []},
    '@divergencetech/ethier/contracts/erc721/BaseOpenSea.sol:ProxyRegistry': {
      abi: [
        {
          inputs: [{internalType: 'address', name: '', type: 'address'}],
          name: 'proxies',
          outputs: [
            {
              internalType: 'contract OwnableDelegateProxy',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
    },
    '@divergencetech/ethier/contracts/erc721/ERC721Common.sol:ERC721Common': {
      abi: [
        {
          inputs: [
            {internalType: 'string', name: 'name', type: 'string'},
            {internalType: 'string', name: 'symbol', type: 'string'},
            {
              internalType: 'address',
              name: 'openSeaProxyRegistry',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'approved',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'Approval',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'operator',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'bool',
              name: 'approved',
              type: 'bool',
            },
          ],
          name: 'ApprovalForAll',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'Transfer',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          inputs: [
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
          ],
          name: 'approve',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{internalType: 'address', name: 'owner', type: 'address'}],
          name: 'balanceOf',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'contractURI',
          outputs: [{internalType: 'string', name: '', type: 'string'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'getApproved',
          outputs: [{internalType: 'address', name: '', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'owner', type: 'address'},
            {internalType: 'address', name: 'operator', type: 'address'},
          ],
          name: 'isApprovedForAll',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'owner', type: 'address'},
            {internalType: 'address', name: 'operator', type: 'address'},
          ],
          name: 'isOwnersOpenSeaProxy',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'name',
          outputs: [{internalType: 'string', name: '', type: 'string'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [{internalType: 'address', name: '', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'ownerOf',
          outputs: [{internalType: 'address', name: '', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'from', type: 'address'},
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
          ],
          name: 'safeTransferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'from', type: 'address'},
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            {internalType: 'bytes', name: '_data', type: 'bytes'},
          ],
          name: 'safeTransferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'operator', type: 'address'},
            {internalType: 'bool', name: 'approved', type: 'bool'},
          ],
          name: 'setApprovalForAll',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'bytes4', name: 'interfaceId', type: 'bytes4'},
          ],
          name: 'supportsInterface',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'symbol',
          outputs: [{internalType: 'string', name: '', type: 'string'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'index', type: 'uint256'}],
          name: 'tokenByIndex',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'owner', type: 'address'},
            {internalType: 'uint256', name: 'index', type: 'uint256'},
          ],
          name: 'tokenOfOwnerByIndex',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'tokenURI',
          outputs: [{internalType: 'string', name: '', type: 'string'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalSupply',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'from', type: 'address'},
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
          ],
          name: 'transferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'newOwner', type: 'address'},
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
    },
    '@divergencetech/ethier/contracts/random/PRNG.sol:PRNG': {abi: []},
    '@divergencetech/ethier/contracts/sales/FixedPriceSeller.sol:FixedPriceSeller':
      {
        abi: [
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
              },
            ],
            name: 'OwnershipTransferred',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address',
              },
            ],
            name: 'Paused',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'buyer',
                type: 'address',
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            name: 'Refund',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'beneficiary',
                type: 'address',
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'numPurchased',
                type: 'uint256',
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            name: 'Revenue',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address',
              },
            ],
            name: 'Unpaused',
            type: 'event',
          },
          {
            inputs: [],
            name: 'beneficiary',
            outputs: [
              {internalType: 'address payable', name: '', type: 'address'},
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [{internalType: 'uint256', name: 'n', type: 'uint256'}],
            name: 'cost',
            outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'owner',
            outputs: [{internalType: 'address', name: '', type: 'address'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [],
            name: 'paused',
            outputs: [{internalType: 'bool', name: '', type: 'bool'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'price',
            outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [],
            name: 'sellerConfig',
            outputs: [
              {
                internalType: 'uint256',
                name: 'totalInventory',
                type: 'uint256',
              },
              {internalType: 'uint256', name: 'maxPerAddress', type: 'uint256'},
              {internalType: 'uint256', name: 'maxPerTx', type: 'uint256'},
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address payable',
                name: '_beneficiary',
                type: 'address',
              },
            ],
            name: 'setBeneficiary',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'uint256', name: '_price', type: 'uint256'},
            ],
            name: 'setPrice',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                components: [
                  {
                    internalType: 'uint256',
                    name: 'totalInventory',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'maxPerAddress',
                    type: 'uint256',
                  },
                  {internalType: 'uint256', name: 'maxPerTx', type: 'uint256'},
                ],
                internalType: 'struct Seller.SellerConfig',
                name: 'config',
                type: 'tuple',
              },
            ],
            name: 'setSellerConfig',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [],
            name: 'totalSold',
            outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'newOwner', type: 'address'},
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
      },
    '@divergencetech/ethier/contracts/sales/Seller.sol:Seller': {
      abi: [
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'buyer',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'Refund',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'beneficiary',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'numPurchased',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'Revenue',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          inputs: [],
          name: 'beneficiary',
          outputs: [
            {internalType: 'address payable', name: '', type: 'address'},
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'n', type: 'uint256'}],
          name: 'cost',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [{internalType: 'address', name: '', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'sellerConfig',
          outputs: [
            {internalType: 'uint256', name: 'totalInventory', type: 'uint256'},
            {internalType: 'uint256', name: 'maxPerAddress', type: 'uint256'},
            {internalType: 'uint256', name: 'maxPerTx', type: 'uint256'},
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address payable',
              name: '_beneficiary',
              type: 'address',
            },
          ],
          name: 'setBeneficiary',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'totalInventory',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'maxPerAddress',
                  type: 'uint256',
                },
                {internalType: 'uint256', name: 'maxPerTx', type: 'uint256'},
              ],
              internalType: 'struct Seller.SellerConfig',
              name: 'config',
              type: 'tuple',
            },
          ],
          name: 'setSellerConfig',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalSold',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'newOwner', type: 'address'},
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
    },
    '@divergencetech/ethier/contracts/utils/OwnerPausable.sol:OwnerPausable': {
      abi: [
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [{internalType: 'address', name: '', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'newOwner', type: 'address'},
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
    },
    '@openzeppelin/contracts/access/Ownable.sol:Ownable': {
      abi: [
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [{internalType: 'address', name: '', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'newOwner', type: 'address'},
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
    },
    '@openzeppelin/contracts/security/Pausable.sol:Pausable': {
      abi: [
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
      ],
    },
    '@openzeppelin/contracts/security/ReentrancyGuard.sol:ReentrancyGuard': {
      abi: [],
    },
    '@openzeppelin/contracts/token/ERC721/ERC721.sol:ERC721': {
      abi: [
        {
          inputs: [
            {internalType: 'string', name: 'name_', type: 'string'},
            {internalType: 'string', name: 'symbol_', type: 'string'},
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'approved',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'Approval',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'operator',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'bool',
              name: 'approved',
              type: 'bool',
            },
          ],
          name: 'ApprovalForAll',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'Transfer',
          type: 'event',
        },
        {
          inputs: [
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
          ],
          name: 'approve',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{internalType: 'address', name: 'owner', type: 'address'}],
          name: 'balanceOf',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'getApproved',
          outputs: [{internalType: 'address', name: '', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'owner', type: 'address'},
            {internalType: 'address', name: 'operator', type: 'address'},
          ],
          name: 'isApprovedForAll',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'name',
          outputs: [{internalType: 'string', name: '', type: 'string'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'ownerOf',
          outputs: [{internalType: 'address', name: '', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'from', type: 'address'},
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
          ],
          name: 'safeTransferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'from', type: 'address'},
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            {internalType: 'bytes', name: '_data', type: 'bytes'},
          ],
          name: 'safeTransferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'operator', type: 'address'},
            {internalType: 'bool', name: 'approved', type: 'bool'},
          ],
          name: 'setApprovalForAll',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'bytes4', name: 'interfaceId', type: 'bytes4'},
          ],
          name: 'supportsInterface',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'symbol',
          outputs: [{internalType: 'string', name: '', type: 'string'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'tokenURI',
          outputs: [{internalType: 'string', name: '', type: 'string'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'from', type: 'address'},
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
          ],
          name: 'transferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
    },
    '@openzeppelin/contracts/token/ERC721/IERC721.sol:IERC721': {
      abi: [
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'approved',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'Approval',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'operator',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'bool',
              name: 'approved',
              type: 'bool',
            },
          ],
          name: 'ApprovalForAll',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'Transfer',
          type: 'event',
        },
        {
          inputs: [
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
          ],
          name: 'approve',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{internalType: 'address', name: 'owner', type: 'address'}],
          name: 'balanceOf',
          outputs: [
            {internalType: 'uint256', name: 'balance', type: 'uint256'},
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'getApproved',
          outputs: [
            {internalType: 'address', name: 'operator', type: 'address'},
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'owner', type: 'address'},
            {internalType: 'address', name: 'operator', type: 'address'},
          ],
          name: 'isApprovedForAll',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'ownerOf',
          outputs: [{internalType: 'address', name: 'owner', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'from', type: 'address'},
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
          ],
          name: 'safeTransferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'from', type: 'address'},
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            {internalType: 'bytes', name: 'data', type: 'bytes'},
          ],
          name: 'safeTransferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'operator', type: 'address'},
            {internalType: 'bool', name: '_approved', type: 'bool'},
          ],
          name: 'setApprovalForAll',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'bytes4', name: 'interfaceId', type: 'bytes4'},
          ],
          name: 'supportsInterface',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'from', type: 'address'},
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
          ],
          name: 'transferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
    },
    '@openzeppelin/contracts/token/ERC721/IERC721Receiver.sol:IERC721Receiver':
      {
        abi: [
          {
            inputs: [
              {internalType: 'address', name: 'operator', type: 'address'},
              {internalType: 'address', name: 'from', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
              {internalType: 'bytes', name: 'data', type: 'bytes'},
            ],
            name: 'onERC721Received',
            outputs: [{internalType: 'bytes4', name: '', type: 'bytes4'}],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
      },
    '@openzeppelin/contracts/token/ERC721/extensions/ERC721Enumerable.sol:ERC721Enumerable':
      {
        abi: [
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'approved',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Approval',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
              {
                indexed: false,
                internalType: 'bool',
                name: 'approved',
                type: 'bool',
              },
            ],
            name: 'ApprovalForAll',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Transfer',
            type: 'event',
          },
          {
            inputs: [
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'approve',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [{internalType: 'address', name: 'owner', type: 'address'}],
            name: 'balanceOf',
            outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'getApproved',
            outputs: [{internalType: 'address', name: '', type: 'address'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'owner', type: 'address'},
              {internalType: 'address', name: 'operator', type: 'address'},
            ],
            name: 'isApprovedForAll',
            outputs: [{internalType: 'bool', name: '', type: 'bool'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{internalType: 'string', name: '', type: 'string'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'ownerOf',
            outputs: [{internalType: 'address', name: '', type: 'address'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'from', type: 'address'},
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'from', type: 'address'},
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
              {internalType: 'bytes', name: '_data', type: 'bytes'},
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'operator', type: 'address'},
              {internalType: 'bool', name: 'approved', type: 'bool'},
            ],
            name: 'setApprovalForAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'bytes4', name: 'interfaceId', type: 'bytes4'},
            ],
            name: 'supportsInterface',
            outputs: [{internalType: 'bool', name: '', type: 'bool'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{internalType: 'string', name: '', type: 'string'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [{internalType: 'uint256', name: 'index', type: 'uint256'}],
            name: 'tokenByIndex',
            outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'owner', type: 'address'},
              {internalType: 'uint256', name: 'index', type: 'uint256'},
            ],
            name: 'tokenOfOwnerByIndex',
            outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'tokenURI',
            outputs: [{internalType: 'string', name: '', type: 'string'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'from', type: 'address'},
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'transferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
      },
    '@openzeppelin/contracts/token/ERC721/extensions/ERC721Pausable.sol:ERC721Pausable':
      {
        abi: [
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'approved',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Approval',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
              {
                indexed: false,
                internalType: 'bool',
                name: 'approved',
                type: 'bool',
              },
            ],
            name: 'ApprovalForAll',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address',
              },
            ],
            name: 'Paused',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Transfer',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address',
              },
            ],
            name: 'Unpaused',
            type: 'event',
          },
          {
            inputs: [
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'approve',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [{internalType: 'address', name: 'owner', type: 'address'}],
            name: 'balanceOf',
            outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'getApproved',
            outputs: [{internalType: 'address', name: '', type: 'address'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'owner', type: 'address'},
              {internalType: 'address', name: 'operator', type: 'address'},
            ],
            name: 'isApprovedForAll',
            outputs: [{internalType: 'bool', name: '', type: 'bool'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{internalType: 'string', name: '', type: 'string'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'ownerOf',
            outputs: [{internalType: 'address', name: '', type: 'address'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'paused',
            outputs: [{internalType: 'bool', name: '', type: 'bool'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'from', type: 'address'},
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'from', type: 'address'},
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
              {internalType: 'bytes', name: '_data', type: 'bytes'},
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'operator', type: 'address'},
              {internalType: 'bool', name: 'approved', type: 'bool'},
            ],
            name: 'setApprovalForAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'bytes4', name: 'interfaceId', type: 'bytes4'},
            ],
            name: 'supportsInterface',
            outputs: [{internalType: 'bool', name: '', type: 'bool'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{internalType: 'string', name: '', type: 'string'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'tokenURI',
            outputs: [{internalType: 'string', name: '', type: 'string'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'from', type: 'address'},
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'transferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
      },
    '@openzeppelin/contracts/token/ERC721/extensions/IERC721Enumerable.sol:IERC721Enumerable':
      {
        abi: [
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'approved',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Approval',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
              {
                indexed: false,
                internalType: 'bool',
                name: 'approved',
                type: 'bool',
              },
            ],
            name: 'ApprovalForAll',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Transfer',
            type: 'event',
          },
          {
            inputs: [
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'approve',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [{internalType: 'address', name: 'owner', type: 'address'}],
            name: 'balanceOf',
            outputs: [
              {internalType: 'uint256', name: 'balance', type: 'uint256'},
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'getApproved',
            outputs: [
              {internalType: 'address', name: 'operator', type: 'address'},
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'owner', type: 'address'},
              {internalType: 'address', name: 'operator', type: 'address'},
            ],
            name: 'isApprovedForAll',
            outputs: [{internalType: 'bool', name: '', type: 'bool'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'ownerOf',
            outputs: [
              {internalType: 'address', name: 'owner', type: 'address'},
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'from', type: 'address'},
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'from', type: 'address'},
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
              {internalType: 'bytes', name: 'data', type: 'bytes'},
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'operator', type: 'address'},
              {internalType: 'bool', name: '_approved', type: 'bool'},
            ],
            name: 'setApprovalForAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'bytes4', name: 'interfaceId', type: 'bytes4'},
            ],
            name: 'supportsInterface',
            outputs: [{internalType: 'bool', name: '', type: 'bool'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [{internalType: 'uint256', name: 'index', type: 'uint256'}],
            name: 'tokenByIndex',
            outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'owner', type: 'address'},
              {internalType: 'uint256', name: 'index', type: 'uint256'},
            ],
            name: 'tokenOfOwnerByIndex',
            outputs: [
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'from', type: 'address'},
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'transferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
      },
    '@openzeppelin/contracts/token/ERC721/extensions/IERC721Metadata.sol:IERC721Metadata':
      {
        abi: [
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'approved',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Approval',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
              {
                indexed: false,
                internalType: 'bool',
                name: 'approved',
                type: 'bool',
              },
            ],
            name: 'ApprovalForAll',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Transfer',
            type: 'event',
          },
          {
            inputs: [
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'approve',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [{internalType: 'address', name: 'owner', type: 'address'}],
            name: 'balanceOf',
            outputs: [
              {internalType: 'uint256', name: 'balance', type: 'uint256'},
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'getApproved',
            outputs: [
              {internalType: 'address', name: 'operator', type: 'address'},
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'owner', type: 'address'},
              {internalType: 'address', name: 'operator', type: 'address'},
            ],
            name: 'isApprovedForAll',
            outputs: [{internalType: 'bool', name: '', type: 'bool'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{internalType: 'string', name: '', type: 'string'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'ownerOf',
            outputs: [
              {internalType: 'address', name: 'owner', type: 'address'},
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'from', type: 'address'},
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'from', type: 'address'},
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
              {internalType: 'bytes', name: 'data', type: 'bytes'},
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'operator', type: 'address'},
              {internalType: 'bool', name: '_approved', type: 'bool'},
            ],
            name: 'setApprovalForAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'bytes4', name: 'interfaceId', type: 'bytes4'},
            ],
            name: 'supportsInterface',
            outputs: [{internalType: 'bool', name: '', type: 'bool'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{internalType: 'string', name: '', type: 'string'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'tokenURI',
            outputs: [{internalType: 'string', name: '', type: 'string'}],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {internalType: 'address', name: 'from', type: 'address'},
              {internalType: 'address', name: 'to', type: 'address'},
              {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            ],
            name: 'transferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
      },
    '@openzeppelin/contracts/utils/Address.sol:Address': {abi: []},
    '@openzeppelin/contracts/utils/Context.sol:Context': {abi: []},
    '@openzeppelin/contracts/utils/Strings.sol:Strings': {abi: []},
    '@openzeppelin/contracts/utils/cryptography/ECDSA.sol:ECDSA': {abi: []},
    '@openzeppelin/contracts/utils/introspection/ERC165.sol:ERC165': {
      abi: [
        {
          inputs: [
            {internalType: 'bytes4', name: 'interfaceId', type: 'bytes4'},
          ],
          name: 'supportsInterface',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
      ],
    },
    '@openzeppelin/contracts/utils/introspection/IERC165.sol:IERC165': {
      abi: [
        {
          inputs: [
            {internalType: 'bytes4', name: 'interfaceId', type: 'bytes4'},
          ],
          name: 'supportsInterface',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
      ],
    },
    '@openzeppelin/contracts/utils/math/Math.sol:Math': {abi: []},
    '@openzeppelin/contracts/utils/structs/EnumerableSet.sol:EnumerableSet': {
      abi: [],
    },
    'contracts/GlitchyBitches.sol:GlitchyBitches': {
      abi: [
        {
          inputs: [
            {internalType: 'string', name: 'name', type: 'string'},
            {internalType: 'string', name: 'symbol', type: 'string'},
            {
              internalType: 'address',
              name: 'openSeaProxyRegistry',
              type: 'address',
            },
            {internalType: 'address[]', name: '_signers', type: 'address[]'},
            {
              internalType: 'address payable',
              name: 'beneficiary',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'approved',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'Approval',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'operator',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'bool',
              name: 'approved',
              type: 'bool',
            },
          ],
          name: 'ApprovalForAll',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'buyer',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'Refund',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'beneficiary',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'numPurchased',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'Revenue',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'Transfer',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          inputs: [],
          name: '_baseTokenURI',
          outputs: [{internalType: 'string', name: '', type: 'string'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
          ],
          name: 'approve',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{internalType: 'address', name: 'owner', type: 'address'}],
          name: 'balanceOf',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'beneficiary',
          outputs: [
            {internalType: 'address payable', name: '', type: 'address'},
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'contractURI',
          outputs: [{internalType: 'string', name: '', type: 'string'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'n', type: 'uint256'}],
          name: 'cost',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'earlyMinting',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'getApproved',
          outputs: [{internalType: 'address', name: '', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'owner', type: 'address'},
            {internalType: 'address', name: 'operator', type: 'address'},
          ],
          name: 'isApprovedForAll',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'owner', type: 'address'},
            {internalType: 'address', name: 'operator', type: 'address'},
          ],
          name: 'isOwnersOpenSeaProxy',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'metapurse',
          outputs: [
            {internalType: 'contract MetaPurse', name: '', type: 'address'},
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'name',
          outputs: [{internalType: 'string', name: '', type: 'string'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'onlyAllowList',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [{internalType: 'address', name: '', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'ownerOf',
          outputs: [{internalType: 'address', name: '', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'price',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'num', type: 'uint256'},
            {internalType: 'bytes', name: 'signature', type: 'bytes'},
          ],
          name: 'safeMint',
          outputs: [],
          stateMutability: 'payable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'from', type: 'address'},
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
          ],
          name: 'safeTransferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'from', type: 'address'},
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            {internalType: 'bytes', name: '_data', type: 'bytes'},
          ],
          name: 'safeTransferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'sellerConfig',
          outputs: [
            {internalType: 'uint256', name: 'totalInventory', type: 'uint256'},
            {internalType: 'uint256', name: 'maxPerAddress', type: 'uint256'},
            {internalType: 'uint256', name: 'maxPerTx', type: 'uint256'},
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'operator', type: 'address'},
            {internalType: 'bool', name: 'approved', type: 'bool'},
          ],
          name: 'setApprovalForAll',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{internalType: 'string', name: 'uri', type: 'string'}],
          name: 'setBaseTokenURI',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address payable',
              name: '_beneficiary',
              type: 'address',
            },
          ],
          name: 'setBeneficiary',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'bool', name: 'allowList', type: 'bool'},
            {internalType: 'bool', name: 'early', type: 'bool'},
          ],
          name: 'setMintingFlags',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: '_price', type: 'uint256'}],
          name: 'setPrice',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'totalInventory',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'maxPerAddress',
                  type: 'uint256',
                },
                {internalType: 'uint256', name: 'maxPerTx', type: 'uint256'},
              ],
              internalType: 'struct Seller.SellerConfig',
              name: 'config',
              type: 'tuple',
            },
          ],
          name: 'setSellerConfig',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'signers',
          outputs: [
            {
              internalType: 'contract SignerRegistry',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'bytes4', name: 'interfaceId', type: 'bytes4'},
          ],
          name: 'supportsInterface',
          outputs: [{internalType: 'bool', name: '', type: 'bool'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'symbol',
          outputs: [{internalType: 'string', name: '', type: 'string'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'index', type: 'uint256'}],
          name: 'tokenByIndex',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'owner', type: 'address'},
            {internalType: 'uint256', name: 'index', type: 'uint256'},
          ],
          name: 'tokenOfOwnerByIndex',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'tokenURI',
          outputs: [{internalType: 'string', name: '', type: 'string'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalSold',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalSupply',
          outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'from', type: 'address'},
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
          ],
          name: 'transferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'newOwner', type: 'address'},
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
    },
    'contracts/GlitchyBitches.sol:SignerRegistry': {
      abi: [
        {
          inputs: [
            {internalType: 'address[]', name: 'signers', type: 'address[]'},
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          inputs: [{internalType: 'address', name: 'signer', type: 'address'}],
          name: 'addSigner',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [{internalType: 'address', name: '', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'address', name: 'signer', type: 'address'}],
          name: 'removeSigner',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'newOwner', type: 'address'},
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'to', type: 'address'},
            {internalType: 'bytes', name: 'signature', type: 'bytes'},
          ],
          name: 'validateSignature',
          outputs: [],
          stateMutability: 'view',
          type: 'function',
        },
      ],
    },
    'contracts/MetaPurse.sol:MetaPurse': {
      abi: [
        {inputs: [], stateMutability: 'nonpayable', type: 'constructor'},
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint8',
              name: 'version',
              type: 'uint8',
            },
          ],
          name: 'VersionChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint8',
              name: 'version',
              type: 'uint8',
            },
          ],
          name: 'VersionRevealed',
          type: 'event',
        },
        {
          inputs: [],
          name: 'CHANGE_COST',
          outputs: [{internalType: 'uint32', name: '', type: 'uint32'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'REVEAL_COST',
          outputs: [{internalType: 'uint32', name: '', type: 'uint32'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'allowanceOf',
          outputs: [{internalType: 'uint32', name: '', type: 'uint32'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'changeToRandomVersion',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
            {internalType: 'uint8', name: 'version', type: 'uint8'},
          ],
          name: 'changeToVersion',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'uint256', name: 'fromId', type: 'uint256'},
            {internalType: 'uint256', name: 'toId', type: 'uint256'},
            {internalType: 'uint32', name: 'amount', type: 'uint32'},
          ],
          name: 'donate',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'glitchyBitches',
          outputs: [
            {internalType: 'contract IERC721', name: '', type: 'address'},
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              components: [
                {internalType: 'uint256', name: 'tokenId', type: 'uint256'},
                {internalType: 'uint32', name: 'amount', type: 'uint32'},
              ],
              internalType: 'struct MetaPurse.Allocation[]',
              name: 'allocs',
              type: 'tuple[]',
            },
          ],
          name: 'increaseAllowance',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'uint256', name: 'num', type: 'uint256'},
            {internalType: 'bool', name: 'extraAllowance', type: 'bool'},
          ],
          name: 'newTokens',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [{internalType: 'address', name: '', type: 'address'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'revealVersion',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'uint64', name: 'rate', type: 'uint64'},
            {internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]'},
          ],
          name: 'setHigherAllowanceRates',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: 'tokenId', type: 'uint256'}],
          name: 'tokenVersion',
          outputs: [{internalType: 'uint8', name: '', type: 'uint8'}],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
          name: 'tokens',
          outputs: [
            {internalType: 'uint8', name: 'version', type: 'uint8'},
            {internalType: 'uint8', name: 'revealed', type: 'uint8'},
            {
              internalType: 'uint64',
              name: 'changeAllowanceStartTime',
              type: 'uint64',
            },
            {internalType: 'int64', name: 'spent', type: 'int64'},
            {internalType: 'uint8', name: 'glitched', type: 'uint8'},
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {internalType: 'address', name: 'newOwner', type: 'address'},
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
    },
  },
  version: '0.8.10+commit.fc410830.Linux.g++',
};

// TODO: put this somewhere else
export const glitchyAbi =
  newGbAbi.contracts['contracts/GlitchyBitches.sol:GlitchyBitches'].abi;
export const metapurseAbi =
  newGbAbi.contracts['contracts/MetaPurse.sol:MetaPurse'].abi;

export const contractAddresses = {
  rinkeby: {
    primary: '0xf6C2e063D391aBDB57709784E83bEBd7dA176023',
    metapurse: '0xDfd6fAa9bE4e43cBcF63d98Ab6C53523EA4f0c89',
  },
  mainnet: {
    primary: '0x7De6581ED7D3113000BfA930A6815cbCf0945019',
    metapurse: '0x26347A4110238bD3fDA7f78b1C2Fc52f0C923F5C',
  },
};
