import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  useTheme,
} from '@mui/material';
import React, {useCallback, useMemo, useState} from 'react';
import {useGb, useGbContractMethod} from '../hooks/gbHooks';
import {CHANGE_VERSION_COST} from '../util/constants';
import {ButtonWithLoader} from './ButtonWithLoader';
import {pluralize} from '../util/util';

// "Set live" button + dialog in carousel image caption
// TODO: maybe consolidate this with ButtonRevealNextVersion.tsx
export const ButtonChangeVersion: React.FC<{
  gbId: number;
  version: number;
}> = ({gbId, version}) => {
  const theme = useTheme();
  const sx =
    theme.gbComponents.MeetPage.GbCard.GbCardCarousel.GbCardCarouselImage
      .caption;

  const [dialogOpen, setDialogOpen] = useState(false);
  const {gb} = useGb(gbId);

  const notEnoughCredits = useMemo(
    () =>
      gb?.data?.changeCredits == null ||
      gb.data.changeCredits < CHANGE_VERSION_COST,
    [gb?.data?.changeCredits]
  );

  const {
    method: changeToVersion,
    status: changeToVersionStatus,
    // error: changeToVersionError, // TODO: display error?
  } = useGbContractMethod('changeToVersion');

  const loading = useMemo(
    () =>
      changeToVersionStatus === 'loading' ||
      changeToVersionStatus === 'waiting',
    [changeToVersionStatus]
  );

  const submit = useCallback(async () => {
    console.log('dispatching revealNextVersion', [gbId]);
    await changeToVersion({variables: [gbId, version], refetchGbIds: [gbId]});
  }, [changeToVersion, gbId, version]);

  if (gb == null) {
    return <></>;
  }

  return (
    <>
      <Button
        variant="gbGradient"
        sx={sx.button}
        disabled={notEnoughCredits || loading}
        onClick={() => setDialogOpen(true)}>
        Set live
      </Button>
      <Typography>
        {pluralize('Glitter Credit', CHANGE_VERSION_COST)}
      </Typography>
      {notEnoughCredits && (
        <Typography variant="body2" sx={sx.error}>
          You have {pluralize('credit', gb.data?.changeCredits ?? 0)}
        </Typography>
      )}
      <Dialog
        onDoubleClick={(e) => e.stopPropagation()} // without this, the image doubleclick (full size view) will still fire
        onClose={() => {
          setDialogOpen(false);
        }}
        maxWidth="md"
        fullWidth
        open={dialogOpen}>
        <DialogContent>
          <Typography variant="h4">Revisit an&nbsp;old&nbsp;friend</Typography>
          <DialogContentText>
            You are changing to a version you’ve already revealed.
          </DialogContentText>
          <DialogContentText>
            This will cost{' '}
            <strong>{pluralize('Glitter Credit', CHANGE_VERSION_COST)}</strong>
            ... but be warned, GBs are free spirits who won’t always cooperate!
          </DialogContentText>
          <DialogActions>
            <ButtonWithLoader
              loading={loading}
              disabled={notEnoughCredits || loading}
              color="primary"
              onClick={submit}>
              {changeToVersionStatus === 'loading'
                ? 'Confirm transaction...'
                : changeToVersionStatus === 'waiting'
                ? 'Loading...'
                : 'Reverse Time!'}
            </ButtonWithLoader>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
