// Don't hard-code urls in the app, add them here
export const gbUrls = {
  home: '/',
  meet: '/meet',
  vision: '/vision',
  faq: '/faq',
  termsAndConditions: '/terms',
  moth: 'https://balter.org/Museum-of-the-Hidden',
  metamaskInstall: 'https://metamask.io/download.html',
  opensea: 'https://opensea.io/collection/glitchy-bitches',
  premint: 'https://www.premint.xyz/gbs-pre-sale-registration/',
  // path without contract
  etherscanPath: {
    rinkeby: 'https://rinkeby.etherscan.io/address/',
    mainnet: 'https://etherscan.io/address/',
  },
  // path without contract
  etherscanContract: {
    rinkeby:
      'https://rinkeby.etherscan.io/address/0xf6C2e063D391aBDB57709784E83bEBd7dA176023',
    mainnet:
      'https://etherscan.io/address/0x7De6581ED7D3113000BfA930A6815cbCf0945019',
  },
  discord: 'https://discord.gg/uvavEjSYVW',
  twitter: 'https://twitter.com/glitchybitches',
  arran: 'https://twitter.com/divergencearran',
  harri: 'https://twitter.com/divergenceharri',
  balterFoundation: 'https://balter.org/',
  kevinRose: 'https://twitter.com/kevinrose',
  brotchain: 'https://twitter.com/brotchain',
  poaw: 'https://linktr.ee/poaw',
  proofXyz: 'https://twitter.com/proof_xyz',
  proofXyzEpisode:
    'https://www.proof.xyz/brotchain-generative-art-built-entirely-on-blockchain/',
};
