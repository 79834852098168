import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useMetamaskContext} from './MetamaskProvider';
import {notEmpty} from '../util/util';
import {useGbContract} from './GbContractContextProvider';
import {useGbContractMethod} from '../hooks/gbHooks';
import {BigNumber} from 'ethers';

type GbUserContextState = {
  account?: string | null; // metamask account string
  ownedCount: null | number; // Number of GBs owned by this user
  ownedGbIds: null | Array<number>; // Array of all the IDs of GBs owned by this user
  fetchUserData(): void;
};

export const GbUserContext = React.createContext<GbUserContextState>({
  account: undefined,
  ownedGbIds: null,
  ownedCount: null,
  fetchUserData: () => false,
});

export const GbUserContextProvider: React.FC = ({children}) => {
  const {glitchyBitchesContract} = useGbContract();

  const {account} = useMetamaskContext();

  const [ownedCount, setOwnedCount] = useState<null | number>(null);
  const [ownedGbIds, setOwnedGbIds] = useState<null | Array<number>>(null);

  const {
    method: balanceOf,
  } = useGbContractMethod('balanceOf'); //, error: balanceOfError} =
  const {
    method: tokenOfOwnerByIndex,
  } = useGbContractMethod('tokenOfOwnerByIndex'); //, error: tokenOfOwnerByIndexError} =

  // To get an account's list of GBs, there are two steps:
  // 1. Get the count of GBs owned by the account (_fetchOwnedCount)
  // 2. Get the gbId based on the index of the token in the account's wallet (_fetchTokenIdByIndex)
  // This function does step 1.
  const _fetchOwnedCount = useCallback(async () => {
    try {
      const gbCount =
        account == null
          ? null
          : // TODO: why tf do i have to do 'as BigNumber'?
            ((await balanceOf({variables: [account]})) as BigNumber);
      if (gbCount != null) {
        setOwnedCount(gbCount.toNumber());
      }
    } catch (e) {
      // TODO: better error handling
      console.log('_fetchOwnedCount error', e);
    }
  }, [account, balanceOf]);

  // To get an account's list of GBs, there are two steps:
  // 1. Find out how many GBs the account owns (_fetchOwnedCount)
  // 2. Get the gbId based on the index of the token in the user's wallet (_fetchTokenIdByIndex)
  // This function does step 2.
  const _fetchTokenIdByIndex = useCallback(
    async (tokenIndex: number) => {
      if (account == null) {
        return null;
      }
      try {
        // TODO: why tf do i have to do 'as BigNumber'?
        const tokenId = (await tokenOfOwnerByIndex({
          variables: [account, tokenIndex],
        })) as BigNumber;
        if (tokenId == null) {
          return null;
        }
        return tokenId.toNumber() as number;
      } catch (e) {
        // TODO: better error handling
        console.log('_fetchTokenIdByIndex error', e);
        return null;
      }
    },
    [account, tokenOfOwnerByIndex]
  );

  const _fetchOwnedGbIds = useCallback(async () => {
    try {
      // console.log('_fetchOwnedGbIds', ownedCount);
      if (ownedCount == null) {
        return;
      }
      const allGbIds = (
        await Promise.all(
          Array(ownedCount)
            .fill(0)
            .map(async (_, i) => {
              return await _fetchTokenIdByIndex(i);
            })
        )
      )
        .filter(notEmpty)
        .reverse();

      // console.log('allGbIds', allGbIds);
      if (allGbIds != null) {
        setOwnedGbIds(allGbIds);
      }
    } catch (e) {
      // TODO: better error handling
      console.log(e);
      return [];
    }
  }, [_fetchTokenIdByIndex, ownedCount]);

  const fetchUserData = useCallback(() => {
    if (
      account == null ||
      glitchyBitchesContract == null ||
      glitchyBitchesContract.provider == null
    ) {
      return;
    }
    _fetchOwnedCount(); // populate ownedCount
    _fetchOwnedGbIds(); // populate ownedGbIds
  }, [_fetchOwnedCount, _fetchOwnedGbIds, account, glitchyBitchesContract]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const state = useMemo(
    () => ({
      account,
      ownedGbIds,
      ownedCount,
      fetchUserData,
    }),
    [account, fetchUserData, ownedCount, ownedGbIds]
  );
  // console.log('gbUserContext state', state);

  return (
    <GbUserContext.Provider value={state}>{children}</GbUserContext.Provider>
  );
};

export const useGbUser = () => {
  return useContext(GbUserContext);
};
