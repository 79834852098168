import React from 'react';
import {Button} from '@mui/material';
import {useMetamaskContext} from './MetamaskProvider';

// Shows a different component depending on Metamask connection state
export const AccountTab: React.FC<{
  noMetamaskComponent: React.ReactElement; // Metamask not installed
  wrongChainComponent?: React.ReactElement; // Metamask set to wrong chain
  loadingComponent?: React.ReactElement; // Metamask installed and connected
  connectedComponent: React.ReactElement; // Metamask installed and connected
  disconnectedComponent: React.ReactElement; // Metamask installed, but not connected
}> = ({
  noMetamaskComponent,
  wrongChainComponent,
  loadingComponent,
  connectedComponent,
  disconnectedComponent,
}) => {
  const {loading, active, error} = useMetamaskContext();

  // Metamask is not installed
  if (window.ethereum == null) {
    return noMetamaskComponent;
  }

  if (error === 'WRONG_CHAIN') {
    return wrongChainComponent ?? <>Wrong chain!</>;
  }

  // Loading connection status
  if (loading) {
    return loadingComponent ?? <>Connecting...</>;
  }

  // Account is already connected. Display 'Your Wallet' <Tab> (or <LinkItem</> if sidebar)
  if (active) {
    return connectedComponent;
  }

  // Metamask is installed, but not connected. Displays <ConnectWalletButton>
  return disconnectedComponent;
};

// The Connect to Metamask button and the associated dialog window
export const ConnectWalletButton: React.FC<{large?: boolean}> = ({large}) => {
  // const [dialogOpen, setDialogOpen] = useState(false);
  // const sx = useTheme();
  const {login} = useMetamaskContext();

  return (
    <>
      <Button
        variant="gbGradient"
        onClick={login}
        sx={large ? {fontSize: {md: '2rem', xs: '1.2rem'}} : {}}>
        Connect your Wallet
      </Button>
      {/* <Dialog
        sx={sx.gbComponents.DialogConnectMetamask.root}
        onClose={() => {
          setDialogOpen(false);
        }}
        maxWidth="lg"
        fullWidth
        open={dialogOpen}>
        <DialogContent>
          <Grid container columns={1} rowSpacing={4}>
            <Grid item xs={1}>
              <Typography variant="h4" gutterBottom>
                Connect your wallet to mint GBs
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <img
                onClick={login}
                src={metamaskConnectButton}
                alt="Connect Metamask"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog> */}
    </>
  );
};
