import {Box, Dialog, Grid, Theme, Typography, useTheme} from '@mui/material';
import {SxProps} from '@mui/system';
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import gbSquiggle from '../assets/images/gb_squiggle.png';
import {useGb, useGbContractMethod, usePrevious} from '../hooks/gbHooks';
import {ButtonChangeVersion} from './ButtonChangeVersion';
import {ButtonRevealNextVersion} from './ButtonRevealNextVersion';
import {useGbFireworks} from './GbFireworksContextProvider';
import {getCoords} from '../util/util';

export const GbCardCarouselImage: React.FC<{
  gbId?: number;
  version?: number;
  selected?: boolean;
  setSelectedVersion?: (v: number) => void;
  spinning?: boolean; // if true, will have infinite card spin animation
  newlyMinted?: boolean; // image shown right after you mint a new gb
  height?: number; // height in px
}> = ({
  gbId,
  version,
  selected,
  setSelectedVersion,
  spinning,
  newlyMinted,
  height,
}) => {
  const {gb} = useGb(gbId);

  const revealedVersion = useMemo(
    () => gb?.data?.revealedVersion,
    [gb?.data?.revealedVersion]
  );
  const previousRevealedVersion = usePrevious(revealedVersion);

  const live = useMemo(
    () => version != null && version === gb?.data?.currentVersion,
    [gb?.data?.currentVersion, version]
  );

  const image = useMemo(
    () => (version == null ? null : gb?.data?.images[version]),
    [gb?.data?.images, version]
  );
  const noImage = image == null;

  const {triggerFireworks} = useGbFireworks();
  const fireworksAnchorRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    // Show fireworks when revealing a new version
    if (
      fireworksAnchorRef.current != null &&
      (newlyMinted ||
        (live &&
          // selected &&
          previousRevealedVersion != null &&
          previousRevealedVersion !== revealedVersion))
    ) {
      const anchorCoords = getCoords(fireworksAnchorRef.current);
      triggerFireworks({
        top: anchorCoords.top - 120,
        left: anchorCoords.left - 105,
      });
    }
  }, [
    live,
    newlyMinted,
    previousRevealedVersion,
    revealedVersion,
    triggerFireworks,
  ]);

  const {
    method: revealNextVersion,
    status: revealNextVersionStatus,
    waiting,
    // error: revealNextVersionError, // TODO: display error?
  } = useGbContractMethod('revealVersion');

  const setSelected = useCallback(() => {
    if (setSelectedVersion != null && version != null) {
      setSelectedVersion(version);
    }
  }, [setSelectedVersion, version]);

  const theme = useTheme();
  // TODO: figure out a better way to do this
  const sx: {
    root: SxProps<Theme>;
    imageBorder: SxProps<Theme>;
    imageContainer: SxProps<Theme>;
    squiggle: React.CSSProperties;
    caption: SxProps<Theme>;
    cardFace: SxProps<Theme>;
    cardFaceBack: SxProps<Theme>;
  } = useMemo(() => {
    const sxRoot =
      theme.gbComponents.MeetPage.GbCard.GbCardCarousel.GbCardCarouselImage;
    return {
      root: {
        ...sxRoot.root,
        ...(live ? sxRoot.live.root : {}),
        ...(height ? {height: `${height}px`} : {}),
      } as SxProps<Theme>,
      imageBorder: {
        ...sxRoot.imageBorder,
        ...(live ? sxRoot.live.imageBorder : {}),
        ...(selected ? sxRoot.selected.imageBorder : {}),
        ...(waiting || spinning ? sxRoot.spinning.imageBorder : {}),
      } as SxProps<Theme>,
      imageContainer: {
        ...sxRoot.imageContainer,
        ...(selected ? sxRoot.selected.imageContainer : {}),
      } as SxProps<Theme>,
      squiggle: {
        ...sxRoot.squiggle,
        ...(selected ? sxRoot.selected.squiggle : {}),
      } as React.CSSProperties,
      caption: sxRoot.caption.root,
      cardFace: {
        ...sxRoot.cardFace,
        ...(noImage ? sxRoot.noImage : {}),
      },
      cardFaceBack: sxRoot.cardFaceBack,
    };
  }, [
    theme.gbComponents.MeetPage.GbCard.GbCardCarousel.GbCardCarouselImage,
    live,
    height,
    selected,
    waiting,
    spinning,
    noImage,
  ]);

  // Dialog that shows the full image on double-click
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = useCallback(() => setDialogOpen(false), []);

  return (
    <Grid
      item
      onClick={setSelected}
      onDoubleClick={() => setDialogOpen(true)}
      sx={sx.root}>
      <Box sx={sx.imageContainer} ref={fireworksAnchorRef}>
        <img src={gbSquiggle} alt="GB Squiggle" style={sx.squiggle} />
        <Box sx={sx.imageBorder}>
          <Box sx={sx.cardFace}>
            {noImage ? <></> : <img src={image.src} alt="GB" />}
          </Box>
          <Box
            sx={{
              ...sx.cardFace,
              ...sx.cardFaceBack,
            }}
          />
        </Box>
        {gb != null && !newlyMinted && (
          <Box sx={sx.caption}>
            {live && (
              <Typography sx={{fontWeight: 'bold', fontSize: '1.2rem'}}>
                Live
              </Typography>
            )}
            {selected &&
              revealedVersion != null &&
              gbId != null &&
              (live ? (
                <></>
              ) : version != null && version <= revealedVersion ? (
                <ButtonChangeVersion gbId={gbId} version={version} />
              ) : version != null && version === revealedVersion + 1 ? (
                <ButtonRevealNextVersion
                  gbId={gbId}
                  onClick={revealNextVersion}
                  status={revealNextVersionStatus}
                />
              ) : (
                <></>
              ))}
          </Box>
        )}
      </Box>
      {!noImage && (
        <Dialog fullWidth maxWidth="xl" open={dialogOpen} onClose={closeDialog}>
          <img src={image.src} alt="Full-size GB" onClick={closeDialog} />
        </Dialog>
      )}
    </Grid>
  );
};
