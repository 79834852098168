import React, {useMemo} from 'react';

// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';

// To add fonts, just import them here and edit the theme's typography.allVariants.font-family
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';

import './App.css';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import {Container, CssBaseline, Grid, ThemeProvider} from '@mui/material';

import {gbMuiThemeDark, gbMuiThemeLight} from './styles/gbTheme';
import {MeetPage} from './components/MeetPage';
import {HomePage} from './components/HomePage';
import {VisionPage} from './components/VisionPage';
import {NavBar} from './components/NavBar';
import {Footer} from './components/Footer';

import {Web3ReactProvider} from '@web3-react/core';
import {MetamaskContextProvider} from './components/MetamaskProvider';
import {getLibrary} from './util/connectors';
import {GbUserContextProvider} from './components/GbUserContextProvider';
import {GbContractContextProvider} from './components/GbContractContextProvider';
import {FaqPage} from './components/FaqPage';
import {gbUrls} from './util/gbUrls';
import {TermsAndConditionsPage} from './components/TermsAndConditionsPage';
import {GbDataContextProvider} from './components/GbDataContextProvider';
import {GbFireworksContextProvider} from './components/GbFireworksContextProvider';

export default function App() {
  // TODO: implement light mode?
  // const [darkMode, setDarkMode] = useState(true);
  const darkMode = true;
  const theme = useMemo(
    () => (darkMode ? gbMuiThemeDark : gbMuiThemeLight),
    [darkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Web3ReactProvider getLibrary={getLibrary}>
        <MetamaskContextProvider>
          <GbContractContextProvider>
            <GbUserContextProvider>
              <GbDataContextProvider>
                <GbFireworksContextProvider>
                  <Router>
                    <div className="App">
                      {/*{false && (*/}
                      {/*  // TODO: why does this still display in production build?*/}
                      {/*  <BreakpointRuler />*/}
                      {/*)}*/}
                      {/*{false && (*/}
                      {/*  // TODO: enable light mode*/}
                      {/*  <Button onClick={() => setDarkMode(false)}>*/}
                      {/*    light mode*/}
                      {/*  </Button>*/}
                      {/*)}*/}
                      <Container>
                        <Grid
                          maxWidth={theme.breakpoints.values.lg}
                          container
                          rowSpacing={12}
                          paddingTop={{xs: '6rem', md: '8rem'}}
                          paddingBottom={{xs: '0rem', md: '2rem'}}>
                          <NavBar />
                          <Grid container item>
                            <Switch>
                              {/* TODO: rename from "meet" to "wallet" or "muses" or "modify" or ? */}
                              <Route path={gbUrls.meet}>
                                <MeetPage />
                              </Route>
                              <Route path={gbUrls.vision}>
                                <VisionPage />
                              </Route>
                              <Route path={gbUrls.faq}>
                                <FaqPage />
                              </Route>
                              <Route path={gbUrls.termsAndConditions}>
                                <TermsAndConditionsPage />
                              </Route>
                              <Route path={gbUrls.home}>
                                <HomePage />
                              </Route>
                            </Switch>
                          </Grid>
                          <Footer />
                        </Grid>
                      </Container>
                    </div>
                  </Router>
                </GbFireworksContextProvider>
              </GbDataContextProvider>
            </GbUserContextProvider>
          </GbContractContextProvider>
        </MetamaskContextProvider>
      </Web3ReactProvider>
    </ThemeProvider>
  );
}
