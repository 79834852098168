import {Grid, useTheme} from '@mui/material';

import React from 'react';
import {GbCardCarouselImage} from './GbCardCarouselImage';

export const GbCardCarousel: React.FC<{
  gbId: number;
  selectedVersion: number;
  setSelectedVersion(version: number): void;
}> = ({gbId, selectedVersion, setSelectedVersion}) => {
  const theme = useTheme();
  const sx = theme.gbComponents.MeetPage.GbCard.GbCardCarousel.root;

  // TODO: on mobile/responsive, live image might be hidden in overflow. figure out design.
  return (
    <Grid container item flexWrap="nowrap" sx={sx}>
      {[...Array(6)].map((_, version) => {
        return (
          <GbCardCarouselImage
            key={version}
            gbId={gbId}
            version={version}
            selected={version === selectedVersion}
            setSelectedVersion={setSelectedVersion}
          />
        );
      })}
    </Grid>
  );
};
