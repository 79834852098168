import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';

import {Remove, Add} from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import {useGbUser} from './GbUserContextProvider';
import {GB_MINT_PRICE} from '../util/constants';
import metamaskLogo from '../assets/images/logo_metamask.svg';
import gbSquiggle from '../assets/images/gb_squiggle.png';
import questionMark from '../assets/images/question_mark.png';
import {ethers} from 'ethers';
import {useGbContract} from './GbContractContextProvider';
import {GbCardCarouselImage} from './GbCardCarouselImage';
import {useGbContractMethod, usePrevious} from '../hooks/gbHooks';
import {gbUrls} from '../util/gbUrls';
import {pluralize} from '../util/util';
import {useGbData} from './GbDataContextProvider';

function useGBMinting() {
  const {account, ownedCount, fetchUserData} = useGbUser();
  const {glitchyBitchesContract} = useGbContract();

  const [mintComplete, setMintComplete] = useState(false);

  const [maxMinted, setMaxMinted] = useState(false);

  // Each account can buy 10 GBs total
  const availableCount = useMemo(
    () => (ownedCount == null ? null : 10 - ownedCount),
    [ownedCount]
  );

  const [mintCount, setMintCount] = React.useState<null | number>(null);

  useEffect(() => {
    setMintCount(availableCount == null ? null : availableCount <= 0 ? 0 : 1);
  }, [availableCount]);

  const incrementDisabled = useMemo(
    () =>
      availableCount == null ||
      mintCount == null ||
      mintCount >= availableCount,
    [availableCount, mintCount]
  );
  const decrementDisabled = useMemo(
    () => mintCount == null || mintCount <= 1,
    [mintCount]
  );

  const decrementMintCount = React.useCallback(() => {
    if (!decrementDisabled && mintCount != null) {
      setMintCount(mintCount - 1);
    }
  }, [mintCount, decrementDisabled]);

  const incrementMintCount = React.useCallback(() => {
    if (!incrementDisabled && mintCount != null) {
      setMintCount(mintCount + 1);
    }
  }, [mintCount, incrementDisabled]);

  const totalPrice = ((mintCount || 0) * GB_MINT_PRICE).toFixed(2);

  const {
    method: safeMint,
    ready,
    loading,
    waiting,
    error,
    clearError,
  } = useGbContractMethod('safeMint');
  const prevWaiting = usePrevious(waiting);

  useEffect(() => {
    if (!waiting && prevWaiting) {
      setMintComplete(true);
    }
  }, [prevWaiting, waiting]);

  const resetForm = useCallback(() => {
    if (availableCount != null) {
      setMintCount(availableCount <= 0 ? 0 : 1);
    }
    clearError();
    setMintComplete(false);
  }, [availableCount, clearError]);

  useEffect(() => {
    if (availableCount != null && availableCount <= 0 && ready) {
      setMaxMinted(true);
    }
  }, [availableCount, ready]);

  const handleMint = useCallback(async () => {
    if (account == null || glitchyBitchesContract == null) {
      // TODO: better error handling
      console.log('Mint error: Unauthorized Metamask account');
      return;
    }

    const price =
      mintCount == null
        ? null
        : ethers.utils.parseEther(GB_MINT_PRICE.toString()).mul(mintCount);

    try {
      if (mintCount == null || price == null) {
        return;
      }
      // TODO: response typing
      await safeMint({variables: [account, mintCount, '0x', {value: price}]});
      fetchUserData();
    } catch (e: any) {
      // TODO: better error handling
      console.log('handleMint error', e);
    }
  }, [account, fetchUserData, glitchyBitchesContract, mintCount, safeMint]);

  return useMemo(
    () => ({
      mintCount,
      availableCount,
      totalPrice,
      incrementMintCount,
      incrementDisabled,
      decrementMintCount,
      decrementDisabled,
      handleMint,
      loading,
      waiting,
      error,
      maxMinted,
      resetForm,
      mintComplete,
    }),
    [
      availableCount,
      decrementDisabled,
      decrementMintCount,
      error,
      handleMint,
      incrementDisabled,
      incrementMintCount,
      loading,
      maxMinted,
      mintComplete,
      mintCount,
      resetForm,
      totalPrice,
      waiting,
    ]
  );
}

// Counter and button to buy GBs
export const MintControls: React.FC = () => {
  const theme = useTheme();
  const sx = theme.gbComponents.HomePage.MintControls;
  const sxGradientButton = theme.gbComponents.buttonGbGradient;

  const {ownedCount, ownedGbIds} = useGbUser();

  const {
    mintCount,
    availableCount,
    totalPrice,
    incrementMintCount,
    incrementDisabled,
    decrementMintCount,
    decrementDisabled,
    resetForm,
    handleMint,
    loading,
    error,
    waiting,
    maxMinted,
    mintComplete,
  } = useGBMinting();

  const newestGbId = useMemo(
    () => (ownedGbIds == null ? null : ownedGbIds[0]),
    [ownedGbIds]
  );

  useEffect(() => {
    // Preload images for unmounted components
    [metamaskLogo, gbSquiggle, questionMark].forEach(preloadSrc => {
      const img = new Image();
      img.src = preloadSrc;
    });
  }, []);

  const submitForm = useCallback(async () => {
    await handleMint();
  }, [handleMint]);

  /* Message for user who has already minted 10 GBs */
  if (maxMinted && !mintComplete) {
    return (
      <Grid
        item
        container
        direction="column"
        rowSpacing={3}
        sx={{...sx.root, ...sx.metamaskCheckout}}>
        <Grid item>
          <Typography sx={{fontSize: '2rem'}}>
            You've already minted <br />
            the maximum number of GBs!
          </Typography>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" href={gbUrls.meet}>
            Visit your GBs
          </Button>
        </Grid>
      </Grid>
    );
  }

  /* Waiting for user to check out in Metamask */
  if (loading) {
    return (
      <Grid
        item
        container
        direction="column"
        rowSpacing={3}
        sx={{...sx.root, ...sx.metamaskCheckout}}>
        <Grid item>
          <Typography sx={{fontSize: '2rem'}}>Checking out with</Typography>
          <Box>
            <img width="300px" src={metamaskLogo} alt="Metamask logo" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  /* Waiting for minting (spinning question mark) */
  if (waiting) {
    return (
      <Box>
        {/*sx={sx.newlyMinted}>*/}
        <GbCardCarouselImage selected spinning height={280} />
        <Button
          variant="gbGradient"
          disabled
          sx={{
            ...sxGradientButton.waiting,
            fontSize: '1.4rem',
            marginTop: '2rem',
          }}>
          Minting {mintCount} new GB{mintCount === 1 ? '' : 's'}...
        </Button>
        {/*<Typography sx={{fontSize: '4rem'}}>*/}
        {/*  Minting {mintCount} new GB{mintCount === 1 ? '' : 's'}!*/}
        {/*</Typography>*/}
      </Box>
    );
  }

  /* Minting complete, preview newly-minted GB */
  if (mintComplete && mintCount != null && newestGbId != null) {
    return (
      <Box>
        {/*<Grid item>*/}
        {/*<Typography variant="body1" sx={{fontSize: '1.5rem'}}>*/}
        {/*  You minted {pluralize('new GB', newlyMintedGbIds.length)}!*/}
        {/*</Typography>*/}

        <NewlyMintedGb
          // gbId={newlyMintedGbIds[newlyMintedGbIds.length - 1]}
          gbId={newestGbId}
        />
        {mintCount > 1 && (
          <Typography variant="body1" sx={{fontSize: '1rem'}}>
            ...and {mintCount - 1} more.
          </Typography>
        )}
        {/*</Grid>*/}
        <Grid
          xs={1}
          container
          item
          flexDirection={'column'}
          alignItems={'center'}
          marginTop={'1rem'}
          justifyContent="center"
          rowSpacing={4}
          columnSpacing={4}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              href={gbUrls.meet}
              sx={{fontSize: '1.4rem'}}>
              Meet your new GB{mintCount > 1 ? 's' : ''}!
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={resetForm}>
              Return to Minting
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  /* Main mint form */
  // TODO: this flashes briefly between the waiting and mintComplete steps. fix it.
  if (!mintComplete && ownedCount != null && availableCount != null) {
    return (
      <Grid item container direction="column" rowSpacing={3} sx={sx.root}>
        <Grid item>
          <Typography sx={sx.text}>
            <span>
              You own{' '}
              <strong>
                {ownedCount === 0 || ownedCount == null ? (
                  '0 GBs'
                ) : (
                  <RouterLink to={gbUrls.meet}>
                    {pluralize('GB', ownedCount)}
                  </RouterLink>
                )}
              </strong>
              ,
            </span>{' '}
            <span>
              and can buy <strong>{availableCount}</strong> more.
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Box sx={sx.counter.root}>
            <IconButton
              disabled={decrementDisabled}
              onClick={decrementMintCount}
              sx={sx.counter.button}>
              <Remove />
            </IconButton>
            <div style={{flexGrow: 1}}>{mintCount}</div>
            <IconButton
              disabled={incrementDisabled}
              onClick={incrementMintCount}
              sx={sx.counter.button}>
              <Add />
            </IconButton>
          </Box>
        </Grid>
        <Grid item width="100%">
          <Button
            size="large"
            fullWidth
            disabled={loading || waiting}
            color="primary"
            variant="contained"
            onClick={submitForm}
            sx={sx.mintButton}>
            Mint!
          </Button>
        </Grid>
        {error != null && (
          <Grid item>
            {/*TODO: error message styling*/}
            <Typography variant={'h4'} sx={{color: 'red'}}>
              <strong>{error}</strong>
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Typography sx={sx.text}>
            <span>
              {GB_MINT_PRICE} ETH x {mintCount}
            </span>{' '}
            <br />
            <span>
              = <strong>{totalPrice} ETH total</strong>
            </span>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return <></>;
};

const NewlyMintedGb: React.FC<{gbId: number}> = ({gbId}) => {
  const {findGbById, fetchGbById} = useGbData();

  useEffect(() => {
    // Load GB data
    fetchGbById(gbId);
  }, [fetchGbById, gbId]);

  const gb = findGbById(gbId);

  if (gb == null) {
    return <GbCardCarouselImage selected spinning height={280} />;
  }

  const {loading, data} = gb;
  if (loading || data == null) {
    return <GbCardCarouselImage selected spinning height={280} />;
  }

  return (
    <GbCardCarouselImage
      gbId={gbId}
      version={data.revealedVersion}
      selected
      height={280}
      spinning
      newlyMinted
    />
  );
};
