export const isProduction = process.env.NODE_ENV === 'production';
export const isDevelopment = process.env.NODE_ENV === 'development';

export const logVerbose = false;

export const ALL_CHAINS = {
  mainnet: 1,
  // ropsten: 3,
  rinkeby: 4,
  // goerli: 5
  // kovan: 42
};

export const CONTRACT_CHAIN: keyof typeof ALL_CHAINS = isProduction
  ? 'mainnet'
  : 'rinkeby';
console.log('CONTRACT_CHAIN', CONTRACT_CHAIN);

// Might be more 'correct' to fetch these from the contract, but this is simpler
export const GB_MINT_PRICE = 0.07; // ETH
export const REVEAL_VERSION_COST: number = 30; // change credits (aka glitter credits)
export const CHANGE_VERSION_COST: number = 10; // change credits (aka glitter credits)
