import {Web3Provider} from '@ethersproject/providers';
import {InjectedConnector} from '@web3-react/injected-connector';
import {ALL_CHAINS} from './constants';

// TODO: types
export const getLibrary = (provider?: any, connector?: any) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
};

export const metamaskConnector = new InjectedConnector({
  supportedChainIds: [ALL_CHAINS.mainnet, ALL_CHAINS.rinkeby],
});
