import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {metamaskConnector} from '../util/connectors';
import {useWeb3React} from '@web3-react/core';
import {useWeb3Provider} from '../hooks/gbHooks';
import {ALL_CHAINS, CONTRACT_CHAIN} from '../util/constants';

declare global {
  interface Window {
    ethereum?: any; // TODO: type this correctly
  }
}

type MetamaskErrorType = null | 'WRONG_CHAIN';

type MetamaskContextState = {
  account?: string | null;
  active: boolean;
  loading: boolean;
  error: MetamaskErrorType;
  login(): void;
}; // & ReturnType<typeof useWeb3React>;

export const MetamaskContext = React.createContext<MetamaskContextState>({
  account: undefined,
  active: false,
  loading: true,
  error: null,
  login: () => false,
  // setError: () => false,
});

export const MetamaskContextProvider: React.FC = ({children}) => {
  const [loading, setLoading] = useState(true);

  const web3React = useWeb3React();

  const login = useCallback(async () => {
    // TODO: error handling
    setLoading(true);
    // const isAuthorized = await metamaskConnector.isAuthorized();
    // if (isAuthorized && !web3React.active && !web3React.error) {
    if (!web3React.active) {
      // && !web3React.error) { // if user cancels metamask auth window, this gets set to UserRejectedRequestError. TODO: need to handle errors better
      await web3React.activate(metamaskConnector);
    }
    setLoading(false);
  }, [web3React]);

  // Ok, web3React.deactivate doesn't actually do anything useful.
  // The connection remains unless the user disconnects via the Metamask browser plugin.
  // As far as I can tell, there's no way to programmatically log out, wtf.
  // See https://dev.to/jacobedawson/build-a-web3-dapp-in-react-login-with-metamask-4chp (ctrl-f deactivate)
  // const logout = useCallback(async () => {
  //   setLoading(true);
  //   if (web3React.active) {
  //     await web3React.deactivate();
  //   }
  //   setLoading(false);
  // }, [web3React]);

  useEffect(() => {
    // Automatically log the user in if they've already authorized metamask
    (async () => {
      setLoading(true);
      const isAuthorized = await metamaskConnector.isAuthorized();
      if (isAuthorized) {
        await login();
      }
      setLoading(false);
    })();
  }, [login]);
  const {provider} = useWeb3Provider();

  const [error, setError] = useState<null | 'WRONG_CHAIN'>(null);

  // Check to see if they're on the right chain
  useEffect(() => {
    (async () => {
      if (provider != null) {
        const {chainId} = await provider.getNetwork();
        console.log('CHAIN', chainId);
        if (chainId !== ALL_CHAINS[CONTRACT_CHAIN]) {
          setError('WRONG_CHAIN');
        }
      }
    })();
  }, [provider]);

  // Set global event handlers:
  // - Reload page if user changes account
  // - Reload page if user changes chain in Metamask
  useEffect(() => {
    const reloadPage = () => window.location.reload();
    (async () => {
      if (window.ethereum != null) {
        console.log('Setting ethereum change handlers');
        window.ethereum.on('accountsChanged', reloadPage);
        window.ethereum.on('chainChanged', reloadPage);
      }
    })();
    return () => {
      if (window.ethereum != null) {
        window.ethereum.removeListener('accountsChanged', reloadPage);
        window.ethereum.removeListener('chainChanged', reloadPage);
      }
    };
  }, []);

  const state = useMemo(
    () => ({
      account: web3React.account,
      active: web3React.active,
      login,
      // logout,
      loading,
      error,
    }),
    [web3React.account, web3React.active, login, loading, error]
  );

  return (
    <MetamaskContext.Provider value={state}>
      {children}
    </MetamaskContext.Provider>
  );
};

export const useMetamaskContext = () => {
  return useContext(MetamaskContext);
};
