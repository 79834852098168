export const getErrorMessage = (e: any) => {
  console.log(e.code);
  if (e.code != null) {
    if (e.code === 'INSUFFICIENT_FUNDS') {
      return 'Error: Insufficient funds';
    }
    if (e.code === 4001) {
      return 'Transaction cancelled';
    }
  }
  return 'Oops! Something went wrong.';
};
