import {Box, Button, ButtonProps, CircularProgress} from '@mui/material';
import React from 'react';

// <Button> with a loading spinner overlay if loading=true
export const ButtonWithLoader: React.FC<ButtonProps & {loading: boolean}> = ({
  loading,
  children,
  ...buttonProps
}) => {
  return (
    <Box position="relative">
      <Button variant="contained" {...buttonProps}>
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={20}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-10px',
            marginLeft: '-10px',
          }}
        />
      )}
    </Box>
  );
};
