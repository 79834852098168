import React, {useMemo} from 'react';
import {Grid, Typography} from '@mui/material';
import {ExternalLink} from './ExternalLink';
import {gbUrls} from '../util/gbUrls';
import {teamMembers} from '../util/teamMembers';

export const VisionPage: React.FC = () => {
  const vision: [React.ReactElement, Array<React.ReactElement>][] = useMemo(
    () => [
      [
        <>
          {teamMembers.glitchyBitches.name} Wants to Help People Make
          Lemonade-like Art Projects
        </>,
        [
          <>
            The Glitchy Bitches NFT collection sows the seeds for the next
            generation of peer-to-peer high end art, fashion, and design
            collaborations. These badass funky muses have many sides, and are
            intended to inspire people to accept their glitches and to turn
            their troubles into ambitious art projects and bold forms of
            creative self-expression. They are creating a space for people to
            have fun, inspire each other, and collaborate on the artistic
            equivalent of lemonade albums from their problems. To give a famous
            example of what we mean, Beyoncé’s Lemonade album turned her
            experience of marital problems into art. Most people going through
            similar struggles feel hopelessly alone. Her art helped her process
            her pain, and also helped millions of others feel less alone. Great
            art often achieves this combination of catharsis and empathy.
          </>,
        ],
      ],
      [
        <>Great Art = Catharsis + Empathy</>,
        [
          <>
            Artists make things that help us contemplate meaning in the world.
            Throughout history, great artists have acknowledged that human
            beings possess both spiritual and animal needs. Plato thought of art
            as beauty, and a divine path that helped us navigate our
            contradictory desires. Enlightenment thinkers saw it as a means for
            us to escape meaningless routines and rise to a higher purpose.
            Anaïs Nin saw it as a way to process and release excess emotion. In
            all these views, art plays a role of catharsis. However, the
            greatest potential of art might be its ability to combine catharsis
            with empathy. Tolstoy saw art as a gateway to empathy between humans
            and others. It helps us relate to the experience of others, and in
            the process helps us understand and empathize with ourselves.
          </>,
          <>
            All that is very lofty, but the point is there is a long history of
            art as an important tool for dealing with the things that keep us up
            at night, both alone and together. {teamMembers.glitchyBitches.name}{' '}
            thinks NFTs might offer a more direct route for communities to
            experience art’s dual blessings of catharsis and empathy than has
            been possible in a long time. The GB Project is therefore an
            experiment along these lines, and the GBs are imbued with the
            following three values:
          </>,
          <ul>
            <li>
              <strong>
                Value #1: Promoting strong, positive mental health vibes, and
                reducing isolation during tough times.
              </strong>{' '}
            </li>
            <li>
              <strong>Value #2: Helping independent artists</strong>
            </li>
            <li>
              <strong>Value #3: Supporting collaborations with artists</strong>{' '}
              that bring attention to the issues that matter to the GB
              community.
            </li>
          </ul>,
        ],
      ],
      [
        <>Giving Back + Balter Foundation Arts Fellowship</>,
        [
          <>
            Everything about the GBs is big and bold. To this end, we partnered
            with the{' '}
            <ExternalLink href={gbUrls.balterFoundation}>
              Balter Foundation
            </ExternalLink>
            . Balter was originally born as a project at Harvard University in
            2020. Balter means ‘to dance without particular skill or grace, but
            with extreme joy’, and the foundation’s mission is using
            collaborative projects as a vehicle to help people find ways to
            creatively work through their problems together. Balter will run an
            annual process that honors global artists whose work helps people
            see and think differently. These artists can be from any domain and
            any part of the world. They need not be involved in NFTs. Every GB
            holder will have an opportunity to nominate an artist for the Balter
            Fellowship and the community will help vet these submissions.
          </>,
        ],
      ],
    ],
    []
  );

  return (
    <Grid container direction="column" rowSpacing={8}>
      <Grid item>
        {' '}
        {/*} xs={2} md={1} sx={{textAlign: {xs: 'center', md: 'initial'}}}> */}
        <Typography variant="h2" gutterBottom>
          Art for What Keeps Us Up at Night
        </Typography>
        {/* <Typography variant="h5" gutterBottom>
          The GB Vision
        </Typography>
        <Divider /> */}
        {vision.map(([title, section], i) => (
          <React.Fragment key={i}>
            <Typography variant="h6">{title}</Typography>
            {section.map((p, j) => (
              <Typography key={j} variant="gbText" component={'div'}>
                {p}
              </Typography>
            ))}
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};
