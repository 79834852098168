import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';
import fireworksGif from '../assets/images/glitter_bomb_fireworks.gif';
// import fireworksGifLoop from '../assets/images/glitter_bomb_fireworks_loop.gif'; // just for testing
import {Box, Theme} from '@mui/material';
import {SxProps} from '@mui/system';

const Fireworks = React.forwardRef<
  HTMLImageElement | null,
  {position: FireworksPosition}
>(({position}, ref) => {
  const sx: SxProps<Theme> = useMemo(
    () => ({
      pointerEvents: 'none',
      position: 'absolute',
      top: `${position.top}px`,
      left: `${position.left}px`,
      zIndex: 3,
      transition: `opacity 1s`,
    }),
    [position.left, position.top]
  );
  return (
    <Box sx={sx}>
      <img ref={ref} src={fireworksGif} alt="Fireworks!" />
    </Box>
  );
});

type GbFireworksContextState = {
  triggerFireworks(fireworksPosition: FireworksPosition | null): void;
};

export const GbFireworksContext = React.createContext<GbFireworksContextState>({
  triggerFireworks: () => false,
});

interface FireworksPosition {
  top: number;
  left: number;
}
export const GbFireworksContextProvider: React.FC = ({children}) => {
  const [showFireworks, setShowFireworks] = useState(false);
  const fireworksTimer = useRef<NodeJS.Timeout | null>(null);
  const [fireworksPosition, setFireworksPosition] = useState<FireworksPosition>(
    {top: 0, left: 0}
  );

  // Set fireworks position as {top, left} in px
  const triggerFireworks = useCallback(({top, left}: FireworksPosition) => {
    setShowFireworks(true);
    setFireworksPosition({
      top,
      left,
    });
    // Remove the fireworks gif after 4 seconds
    fireworksTimer.current = setTimeout(() => {
      setShowFireworks(false);
      fireworksTimer.current = null;
    }, 4000);

    return () => {
      if (fireworksTimer.current) {
        clearTimeout(fireworksTimer.current);
      }
    };
  }, []);

  const state = useMemo(() => ({triggerFireworks}), [triggerFireworks]);

  // console.log('fireworksPosition', fireworksPosition);

  return (
    <GbFireworksContext.Provider value={state}>
      {children}
      {showFireworks && <Fireworks position={fireworksPosition} />}
    </GbFireworksContext.Provider>
  );
};

export const useGbFireworks = () => {
  return useContext(GbFireworksContext);
};
