import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  useTheme,
} from '@mui/material';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useGb} from '../hooks/gbHooks';
import {REVEAL_VERSION_COST} from '../util/constants';
import {pluralize} from '../util/util';
import {ButtonWithLoader} from './ButtonWithLoader';
import {TransactionState} from '../types/gbTypes';
import {AbiMetapurse} from '../types/generated/abi-metapurse-0.8.10+commit.fc410830.Linux.g++';

// "Reveal next version" button + dialog in carousel image caption
// TODO: maybe consolidate this with ButtonChangeVersion.tsx
export const ButtonRevealNextVersion: React.FC<{
  gbId: number;
  onClick({
    variables,
    refetchGbIds,
  }: {
    variables: Parameters<AbiMetapurse['revealVersion']>;
    refetchGbIds?: number[];
  }): Promise<any>; // TODO: no any
  status: TransactionState;
}> = ({gbId, onClick: revealNextVersion, status: revealNextVersionStatus}) => {
  const theme = useTheme();
  const sx =
    theme.gbComponents.MeetPage.GbCard.GbCardCarousel.GbCardCarouselImage
      .caption;

  const [dialogOpen, setDialogOpen] = useState(false);
  const {gb} = useGb(gbId);

  const notEnoughCredits = useMemo(
    () =>
      gb?.data?.changeCredits == null ||
      gb.data.changeCredits < REVEAL_VERSION_COST,
    [gb?.data?.changeCredits]
  );

  const loading = useMemo(
    () => revealNextVersionStatus === 'loading',
    [revealNextVersionStatus]
  );
  const waiting = useMemo(
    () => revealNextVersionStatus === 'waiting',
    [revealNextVersionStatus]
  );

  const sxButton = useMemo(
    () => ({
      ...sx.button,
      ...(waiting ? sx.buttonWaiting : {}),
    }),
    [waiting, sx.button, sx.buttonWaiting]
  );

  const submit = useCallback(async () => {
    console.log('dispatching revealNextVersion', [gbId]);
    await revealNextVersion({variables: [gbId], refetchGbIds: [gbId]});
  }, [gbId, revealNextVersion]);

  useEffect(() => {
    // Close the dialog when the transaction is confirmed
    if (waiting) {
      setDialogOpen(false);
    }
  }, [revealNextVersionStatus, waiting]);

  if (gb == null) {
    return <></>;
  }

  return (
    <Box>
      <Button
        variant="gbGradient"
        sx={sxButton}
        disabled={notEnoughCredits ?? waiting}
        onClick={() => setDialogOpen(true)}>
        {waiting ? 'Revealing...' : 'Reveal next version'}
      </Button>
      {!waiting && (
        <Typography>
          <strong>{REVEAL_VERSION_COST}</strong> Glitter Credits
        </Typography>
      )}
      {notEnoughCredits && !waiting && (
        <Typography variant="body2" sx={sx.error}>
          You have{' '}
          <strong>{pluralize('credit', gb.data?.changeCredits ?? 0)}</strong>
        </Typography>
      )}
      <Dialog
        onDoubleClick={(e) => e.stopPropagation()} // without this, the image doubleclick (full size view) will still fire
        onClose={() => {
          setDialogOpen(false);
        }}
        maxWidth="md"
        fullWidth
        open={dialogOpen}>
        <DialogContent>
          <Typography variant="h4">Reveal&nbsp;a new&nbsp;version</Typography>
          <DialogContentText>
            You are choosing to meet a new version of your GB. This will cost{' '}
            <strong>30 Glitter Credits</strong>.
          </DialogContentText>
          <DialogActions>
            <ButtonWithLoader
              loading={loading}
              disabled={notEnoughCredits || loading}
              color="primary"
              // sx={{marginTop: gbMuiThemeDark.spacing(2)}}
              onClick={submit}>
              {loading
                ? 'Confirm transaction...'
                : waiting
                ? 'Revealing...'
                : 'Meet her now!'}
            </ButtonWithLoader>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
