import React from 'react';
import {Box, Divider, Grid, Paper, Typography} from '@mui/material';
import {GbCard} from './GbCard';
import {useGbUser} from './GbUserContextProvider';
import {Redirect} from 'react-router';
import {gbUrls} from '../util/gbUrls';

// TODO: rename from "meet" to "wallet" or "muses" or "modify" or ?
export const MeetPage: React.FC = () => {
  const gbUser = useGbUser();

  if (gbUser == null) {
    return <Redirect to={gbUrls.home} />;
  }

  return (
    <Grid container direction="column" rowSpacing={6}>
      <Grid container item rowSpacing={6}>
        <Grid item sx={{textAlign: {xs: 'center', md: 'initial'}}}>
          {/* TODO: hr across whole page */}
          <Typography variant="h2" gutterBottom>
            Meet your Muse
          </Typography>
          <Typography variant="body1" gutterBottom>
            {/* Here you can interact with your GBs—including asking them to reveal
            new sides to their personalities, switching back to already-seen
            versions, and donating Glitter Credits (needed for changes) from one
            GB to another. */}
            Here you can ask your GBs to reveal new sides to their
            personalities, or revert themselves back to already-revealed
            versions.
          </Typography>
          <Divider />
          <Typography variant="gbText" gutterBottom>
            <strong>Click</strong> on an already-revealed version to see her
            attributes. <strong>Double click</strong> your muse to see her in
            all her full-size glory.
          </Typography>
          <Typography variant="gbText" gutterBottom>
            It costs <strong>30 Glitter Credits</strong> to reveal a new
            version, and <strong>10 Glitter Credits</strong> to revert.
          </Typography>
          <Typography variant="gbText" gutterBottom>
            <strong>No Glitter? No problem!</strong> Just open up a Metapurse to
            transfer Glitter Credits between GBs.
          </Typography>
        </Grid>
        <Grid container item>
          {/* empty state (no GBs) */}
          {gbUser.ownedGbIds == null || gbUser.ownedGbIds.length === 0 ? (
            <Paper variant="gbPaper" sx={{width: '100%', padding: 0}}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '2rem',
                  textAlign: 'center',
                }}>
                Your GBs will appear here. If you've just bought your first GB,
                it can take a few moments for the transaction to complete.
              </Box>
            </Paper>
          ) : (
            gbUser.ownedGbIds.map((gbId, i) => <GbCard key={i} gbId={gbId} />)
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
