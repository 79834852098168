import {Grid, Link, Tooltip, useTheme} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {gbUrls} from '../util/gbUrls';
import {AccountTab} from './AccountTab';
import {Link as RouterLink} from 'react-router-dom';
import {ExternalLink} from './ExternalLink';
import {useGbUser} from './GbUserContextProvider';
import {useMetamaskContext} from './MetamaskProvider';
import {CONTRACT_CHAIN, GB_MINT_PRICE} from '../util/constants';
import {useGbContractMethod} from '../hooks/gbHooks';
import {BigNumber} from 'ethers';

export const MintInfo: React.FC = () => {
  const theme = useTheme();
  const sx = theme.gbComponents.MintInfo;
  const user = useGbUser();
  const {login} = useMetamaskContext();

  const truncatedAccount = useMemo(() => {
    if (user.account == null) {
      return '';
    }
    return `${user.account.substring(0, 5)}...${user.account.substring(
      user.account.length - 4
    )}`;
  }, [user.account]);

  type MintInfo = [string, string | React.ReactElement];

  // totalSupply is the total number of GBs that have been minted
  const [totalSupply, setTotalSupply] = useState<number | null>(null);
  const {method: getTotalSupply} = useGbContractMethod('totalSupply');

  useEffect(() => {
    try {
      (async () => {
        const sold = (await getTotalSupply()) as BigNumber;
        if (sold != null) {
          setTotalSupply(sold.toNumber());
        }
      })();
    } catch (e) {
      // TODO: better error
      console.log(e);
    }
  }, [getTotalSupply]);

  const mintInfo: MintInfo[] = React.useMemo(() => {
    const baseTabs: MintInfo[] = [
      ['Limits', '10/Tx, 10/wallet'],
      ['Price', `${GB_MINT_PRICE} ETH`],
      [
        'Sold',
        <>
          {totalSupply == null ? (
            '-'
          ) : (
            <ExternalLink href={gbUrls.opensea}>{totalSupply}</ExternalLink>
          )}{' '}
          / 10101
        </>,
      ],
      [
        'Contract',
        <ExternalLink href={gbUrls.etherscanContract[CONTRACT_CHAIN]}>
          ERC-721
        </ExternalLink>,
      ],
      [
        'License',
        <RouterLink to={gbUrls.termsAndConditions}>See details</RouterLink>,
      ],
    ];

    return [
      [
        'Account',
        <AccountTab
          noMetamaskComponent={
            <ExternalLink href={gbUrls.metamaskInstall}>
              Install MetaMask
            </ExternalLink>
          }
          connectedComponent={
            <Tooltip
              arrow
              title={user?.account || 'Account'}
              placement="top-end">
              <ExternalLink
                href={`${gbUrls.etherscanPath[CONTRACT_CHAIN]}${user?.account}`}>
                {truncatedAccount}
              </ExternalLink>
            </Tooltip>
          }
          disconnectedComponent={
            <Link href="#" onClick={login}>
              Connect wallet
            </Link>
          }
        />,
      ],
      ...baseTabs,
    ];
  }, [login, totalSupply, truncatedAccount, user?.account]);

  return (
    <Grid container columns={2} sx={sx.root}>
      {mintInfo.map(([k, v], i) => {
        return (
          <React.Fragment key={i}>
            <Grid item xs={1} sx={{fontWeight: 'bold'}}>
              {k}
            </Grid>
            <Grid item xs={1} sx={{textAlign: 'right'}}>
              {v}
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
