// Get json from a remote url
export async function fetchJson<T>(jsonUrl: string) {
  try {
    let response = await fetch(jsonUrl, {mode: 'cors'});
    // console.log('json', response);
    let responseJson = await response.json();
    return responseJson as T;
  } catch (error) {
    // TODO: error handling
    console.error(error);
    throw error;
  }
}

// Typesafe way to filter empties from array
// See https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

// Capitalize the first letter of a word
export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

// Pluralizes a string (or not) based on count
// e.g. pluralize('credit', 2) => 'credits'
export const pluralize = (word: string, count: number) => {
  return (
    <span style={{whiteSpace: 'nowrap'}}>{`${count} ${word}${
      count === 1 ? '' : 's'
    }`}</span>
  );
};

// Get document coordinates of the element
export const getCoords = (element: HTMLElement) => {
  const box = element.getBoundingClientRect();
  return {
    top: box.top + window.scrollY,
    right: box.right + window.scrollX,
    bottom: box.bottom + window.scrollY,
    left: box.left + window.scrollX,
  };
};
