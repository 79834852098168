import React from 'react';
import {Grid, Stack, Typography, useTheme} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';

import twitterLogo from '../assets/images/logo_twitter_2x.png';
import discordLogo from '../assets/images/logo_discord_2x.png';
import {ExternalLink} from './ExternalLink';
import {gbUrls} from '../util/gbUrls';
import {CONTRACT_CHAIN} from '../util/constants';

export const Footer: React.FC = () => {
  const theme = useTheme();
  const sx = theme.gbComponents.Footer;

  return (
    <Grid container item columns={3} sx={sx.root} spacing={2} alignItems="end">
      <Grid item xs={3} md={2}>
        <ExternalLink href={gbUrls.twitter}>
          <img src={twitterLogo} alt="Twitter logo" />
        </ExternalLink>
        <ExternalLink href={gbUrls.discord}>
          <img src={discordLogo} alt="Discord logo" />
        </ExternalLink>
        <Typography variant="caption">
          © Glitchy Bitches. Made with coffee, contemplation, love, sweat,
          tears, RSI, jokes, and nachos.
        </Typography>
      </Grid>
      <Grid item xs={3} md={1}>
        <Stack sx={sx.links} direction="column">
          <RouterLink to={gbUrls.home}>Home / Minting</RouterLink>
          <RouterLink to={gbUrls.meet}>Meet your Muse</RouterLink>
          <RouterLink to={gbUrls.vision}>Vision</RouterLink>
          <RouterLink to={gbUrls.faq}>FAQ</RouterLink>
          <RouterLink to={gbUrls.termsAndConditions}>
            Terms & Conditions
          </RouterLink>
          <ExternalLink href={gbUrls.opensea}>View on OpenSea</ExternalLink>
          <ExternalLink href={gbUrls.etherscanContract[CONTRACT_CHAIN]}>
            View on EtherScan
          </ExternalLink>
        </Stack>
      </Grid>
    </Grid>
  );
};
