import React, {useMemo} from 'react';
import gbSample0 from '../assets/images/gb_samples/gb_sample_0.jpg';
import gbSample1 from '../assets/images/gb_samples/gb_sample_1.jpg';
import gbSample2 from '../assets/images/gb_samples/gb_sample_2.jpg';
import gbSample3 from '../assets/images/gb_samples/gb_sample_3.jpg';
import gbSample4 from '../assets/images/gb_samples/gb_sample_4.jpg';
import gbSample5 from '../assets/images/gb_samples/gb_sample_5.jpg';
import gbSample6 from '../assets/images/gb_samples/gb_sample_6.jpg';
import gbSample7 from '../assets/images/gb_samples/gb_sample_7.jpg';
import {
  Avatar,
  Button,
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import {MintInfo} from './MintInfo';
import {MintControls} from './MintControls';
import {ExternalLink} from './ExternalLink';
import {gbUrls} from '../util/gbUrls';
import {AccountTab, ConnectWalletButton} from './AccountTab';
import {CONTRACT_CHAIN} from '../util/constants';
import {capitalize} from '../util/util';
import {teamMembers} from '../util/teamMembers';

export const HomePage: React.FC = () => {
  const theme = useTheme();
  const sx = theme.gbComponents.HomePage;

  const aboutTheGbsText = useMemo(
    () => [
      [
        `They're Emotional Alchemists`,
        `Glitchy Bitches are fierce forces that help you let shit go and grow more creative mojo. The 10,101 unique dynamic portraits represent emotional alchemists who help their holders turn pain, past challenges, and other human "glitches" into creative fuel and renewed energy.`,
      ],
      [
        `They Change Over Time`,
        `Like us humans, the GBs are complex characters. Over time, as your relationship develops, your GB can reveal new sides to her personality. It is up to the holder to instigate these changes, using the "Glitter Credits" you accrue in your "Metapurse" each day… although, depending on your GB's mood, she may not respond quite as you expect. Other surprises may be hidden in their portraits.`,
      ],
      [
        `They Use the B-word`,
        `When used positively, the b-word is a term of endearment amongst friends or describes a badass woman (see Cardi B, Beyonce, Tina Fey, Madonna's use). It has strength at its roots. Artemis, the Greek goddess of the moon, wilderness, hunting, and fertility, is thought to have inspired the word. We do not support unkind uses of the word. If you're supportive of us, but shy about this word, just call us The GB project.`,
      ],
      [
        `They're Generous`,
        `Our "G" doesn't only stand for Glitchy. The GBs are also Generous. We donated 25% of primary sales to curating art that reflects the collection's values.`,
      ],
    ],
    []
  );

  const extraSpecialGbsText = useMemo(
    () => [
      [
        `100 Queen Gs`,
        [
          `The Queen Gs live in an exceptionally peaceful world, one that is
            arduous to reach, but considered well worth the effort for those who
            endure the journey. You’ll find evidence that some of the GBs have
            glimpsed this world.`,
        ],
      ],
      [
        `42 Glitchies`,
        [
          `Glitches are best known as unexpected things that happen to
            computers. Maybe we should think of unexpected things that happen to
            humans as glitches, too. They can be perceived as bad, but they are
            not, in themselves, bad; that judgment comes from our perception.
            Glitches, from another point of view, are also beautiful.`,
        ],
      ],
      [
        `1 Creator`,
        [
          `The Creator has mastered the art of creative expression that
            generates empathy and catharsis for others.`,
        ],
      ],
      [
        `1 Emerging Creator`,
        [
          `The Emerging Creator represents up-and-coming creative talent and
            the importance of staying open to new ways of seeing and thinking.`,
        ],
      ],
    ],
    []
  );

  const whatIsUnique = useMemo(
    () => [
      [
        `Interactive art piece with a discoverable narrative`,
        [
          `Most NFT collections are designed to be used as colorful profile pictures (pfps) on social media, and were never intended to have deeper artistic or educational meaning, The GB Project is different. The entire GB collection is an interactive art piece and metaphor for a multi-dimensional self that is trying to make itself whole again over time. The portraits are made of 600+ hand-drawn elements by artist GlitchyBitches. Each element references a psychological and philosophical concept related to the challenge of uniting multiple identities. These elements were algorithmically assembled into portraits based on the artist’s composition logic to generate 10,101 portraits, each with six ‘evolutions’ or dimensions of self.`,
        ],
      ],

      [
        `Each portrait can visually morph into a new self using our custom smart contract`,
        [
          `Each day, GBs accumulate the ability to change over time. Only the current holder of a portrait can initiate a change request. These change requests occur on the blockchain and are enabled by a novel crediting system called Glitter (we invented this system to enable dynamic behavior yet avoid creating an ERC20 token, which can sometimes be financially misused). Once the request goes through, your GB’s portrait visually transforms, as does her associated metadata. Each evolution is not totally random; her core self characteristics remain recognizable. The way she responds to requests to change can vary based in part on the portrait's embedded personality. We playfully call this BQ, or 'bitchiness quotient’. For those who prefer deeper metaphors, it represents the tricky skill of maintaining healthy boundaries. BQ is visible in portrait metadata. Portraits with high BQ can flip off their holders - a playful visual way of rebelling when someone tells you to change.`,
        ],
      ],
      [
        `GB portraits can communicate with one another on-chain`,
        [
          `Another project-level dynamic feature is that GB portraits are able to communicate with other portraits by sending them Glitter. Glitter is a metaphor for joy and sharing it is a metaphor for friendship. Sharing Glitter also functionally enables the receiving GB to attain change credits faster, and therefore evolve faster.`,
        ],
      ],
      [
        `Hidden interactive games build up to let you resolve the ultimate puzzle of The GBs`,
        [
          `This project contains symbols that can be decoded to reveal multiple puzzles. When and if people crack the code, they'll be able to figure out how all GB portraits become pieces of several large puzzles. Solving the larger puzzles reveals new art, as well as resolves the concept of the piece: how the metaphorical self becomes whole again. All of the above already exists, built into the original art and contracts.`,
        ],
      ],
      [
        `GB collectors ultimately curate the final version of the collection`,
        [
          `The collector’s wallet shows all previously revealed versions of a GB; however, a public viewer will only ever see the collector’s chosen version. In this way, the holder also curates the visible version of the portrait. At the end of the GB Project, when all GBs have evolved, holders' choices become permanent, meaning that they will have collectively curated the final public state of the collection.`,
        ],
      ],
    ],
    []
  );

  return (
    <Grid container direction="column" rowSpacing={{xs: 8, md: 12}}>
      {/* Header */}
      <Grid
        container
        item
        // textAlign={{xs: 'center', sm: 'initial'}}
        sx={sx.header}>
        <Typography variant="h2" gutterBottom>
          Visually Morphing Muses
        </Typography>
        {/* TODO: better word-wrapping on mobile  */}
        <Typography variant="h4" gutterBottom>
          Hand-drawn, high-tech portraits that visually evolve over time to
          reveal puzzles and hidden art
          {/* <strong>10,101 Portraits</strong> with{' '}
          <strong>420+&nbsp;Unique&nbsp;Attributes</strong>{' '}
          that&nbsp;change&nbsp;over&nbsp;time. */}
        </Typography>
        {/* <Typography variant="h6">
          The GBs are your ticket to a new kind of{' '}
          <RouterLink to={gbUrls.vision}>creative&nbsp;community</RouterLink>.
        </Typography> */}
      </Grid>
      {/* Top images */}
      <SampleImages position="top" />
      <Grid item sx={sx.ticket}>
        <Typography variant="h3" gutterBottom>
          The GBs are Dynamic NFTs
        </Typography>
        <Typography variant="h5" gutterBottom>
          This collection pushes the boundaries of the blockchain as an artistic
          medium
        </Typography>
        <Typography variant="gbText">
          The GB Collection is unique in several ways. First, the whole project
          is an interactive art piece with a discoverable narrative. Second,
          each portrait can visually morph into a new self using our custom
          smart contract. Third, GB portraits can communicate with one another
          on-chain. Fourth, hidden interactive games build up to let you resolve
          the ultimate puzzle of The GBs. Finally, GB collectors ultimately
          curate the final version of the collection. Learn more in the section
          below on what is unique about this project.
        </Typography>
      </Grid>

      {/* Minting */}
      <Grid container item columns={3} columnSpacing={2} rowSpacing={6}>
        <Grid item xs={3} md={3} sx={sx.getYourGb}>
          <Typography variant="h3" gutterBottom textAlign={{xs: 'center'}}>
            Mint your GB
          </Typography>
          <Box
            display={'flex'}
            justifyContent={'center'}
            sx={{minHeight: '420px'}}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '2rem',
                margin: '2rem auto',
              }}>
              <AccountTab
                noMetamaskComponent={
                  <Button
                    variant="contained"
                    href={gbUrls.metamaskInstall}
                    target="_blank"
                    sx={{fontSize: '2rem', margin: '2rem auto'}}
                    rel="noopener noreferrer">
                    Install MetaMask
                  </Button>
                }
                wrongChainComponent={
                  <>
                    Wrong chain! <br />
                    Please switch to {capitalize(CONTRACT_CHAIN)} to enable
                    minting.
                  </>
                }
                connectedComponent={<MintControls />}
                disconnectedComponent={<ConnectWalletButton large />}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item container justifyContent="center">
          <MintInfo />
        </Grid>
      </Grid>

      {/* Video + About the GBs */}
      <Grid
        container
        item
        columns={{md: 2}}
        sx={sx.aboutTheGbs.root}
        columnSpacing={6}>
        <Grid item md={1}>
          <Box sx={sx.aboutTheGbs.video}>
            <iframe
              title="GB preview video"
              src="https://player.vimeo.com/video/552880740?h=b2ce019f8b&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </Box>
        </Grid>
        <Grid item md={1}>
          <Typography variant="h3">
            The GBs are
            <br />
            Modern Muses
          </Typography>
          {aboutTheGbsText.map(([title, text], i) => (
            <React.Fragment key={i}>
              <Typography variant="h5">{title}</Typography>
              <Typography variant="gbText">{text}</Typography>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>

      {/* Kevin Rose podcast */}
      <Grid item>
        <Typography variant="h3" gutterBottom>
          Pre-launch Buzz on
          <br />
          <strong>
            Kevin Rose's <em>Proof</em>
          </strong>{' '}
          Podcast
        </Typography>
        <Typography variant="gbText">
          We are feeling lucky & grateful to have been mentioned on{' '}
          <ExternalLink href={gbUrls.kevinRose}>@kevinrose</ExternalLink>'s{' '}
          <ExternalLink href={gbUrls.proofXyz}>@proof_xyz</ExternalLink>{' '}
          podcast. Proof is an in-depth NFT coverage podcast hosted by Kevin
          Rose, who Time Magazine calls one of "the 25 most influential people
          on the web." The 10.14.21 episode mentions us at minute 40:50, and
          overall dives into the mind-bending math+art work of{' '}
          <ExternalLink href={gbUrls.brotchain}>@brotchain</ExternalLink>.{' '}
        </Typography>
        <Button
          sx={{marginTop: '1rem'}}
          href={gbUrls.proofXyzEpisode}
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          color="primary">
          Listen to the Podcast
        </Button>
      </Grid>
      <TeamMembers />

      {/* Muses on a Mission */}
      <Grid item>
        <Typography variant="h3" gutterBottom>
          Muses on a Mission
        </Typography>
        <Typography variant="gbText">
          The GBs are on a mental health mission to get to the peaceful world of
          the Queen Gs. Their goal is to learn to travel through what
          psychologists call adversarial growth and philosophers describe as the
          art of turning our greatest obstacles into our greatest strengths. The
          GBs therefore represent values of: resilience, optimism, strength,
          connection, artistic discovery and expression, and collaboration.
        </Typography>
      </Grid>

      {/* Extra-Special GBs */}
      <Grid container item columns={2} columnSpacing={6}>
        <Grid item xs={2} md={1}>
          <Typography variant="h3" gutterBottom>
            Extra-Special GBs
          </Typography>
          <Typography variant="gbText">
            <strong>Every GB is unique, but some are extra rare.</strong>
          </Typography>
          {extraSpecialGbsText.map(([title, text], i) => (
            <React.Fragment key={i}>
              <Typography variant="h5">{title}</Typography>
              <Typography variant="gbText">{text}</Typography>
            </React.Fragment>
          ))}
        </Grid>

        {/* Details */}
        <Grid item xs={2} md={1}>
          <Typography variant="h3" gutterBottom>
            Details
          </Typography>
          <Typography variant="gbText">
            There are 170 billion (1.7E+11) ways to generate just the face of a
            GB, and 1 octillion (1.01048E+27) possible combinations in the
            collection. {teamMembers.glitchyBitches.name} developed dozens of
            custom, computational aesthetic heuristics to winnow down this state
            space. This iterative exploration led her to hard code aesthetic
            rules that ultimately generated the desired collection of 10,101
            GBs.
          </Typography>
          <Typography variant="gbText">
            This is a collection made for building a lasting community over
            time. We aimed to push both the quality of art and tech of NFT
            portraits, and for every portrait to have a story behind it.
          </Typography>
          <Grid container item columns={2} columnSpacing={2}>
            <Grid item xs={2} md={1}>
              <ul>
                <li>60,606 Portraits (6 per NFT)</li>
                <li>10,000 Signatures</li>
                <li>14 Types</li>
                <li>4,111 backgrounds</li>
                <li>13 Levels of BQ</li>
                <li>62 Body Fills</li>
                <li>9 Body Positions </li>
                <li>31 Left Hand Versions </li>
                <li>19 Right Hand Versions</li>
                <li>6 Body Sizes </li>
                <li>303 Underwear Combinations </li>
              </ul>
            </Grid>
            <Grid item xs={2} md={1}>
              <ul>
                <li>7 Faces </li>
                <li>9 Lip Colors </li>
                <li>31 Makeup Styles </li>
                <li>2 Brow Accessories </li>
                <li>15 Cheeks </li>
                <li>14 Earrings </li>
                <li>5 Eye Colors</li>
                <li>105 Hair Styles </li>
                <li>17 Neck Accessories </li>
                <li>15 Nail Colors</li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Philanthropy */}
      <Grid container item sx={sx.philanthropy}>
        <Typography variant="h3" gutterBottom>
          What is Unique About The GB Project?
        </Typography>
        {whatIsUnique.map(([title, text], i) => (
          <React.Fragment key={i}>
            <Typography variant="h5">{title}</Typography>
            <Typography variant="gbText">{text}</Typography>
          </React.Fragment>
        ))}
      </Grid>
      <Grid container item sx={sx.philanthropy}>
        <Typography variant="h3" gutterBottom>
          25% of Sales Support:
          <br /> Healing, Community, & Creative&nbsp;Collabs
        </Typography>
        <Typography variant="gbText">
          Humans aren’t the only ones who need healing, our planet does too. We
          offset the estimated impact of the GB collection being created and
          traded on the Ethereum blockchain in full.
        </Typography>
        <Typography variant="gbText">
          To ensure that we build a community with lasting impact, we donate all
          that remains from 25% of primary sales to organizations and creative
          projects that help or inspire people within our cause areas:
        </Typography>
        <Typography variant="gbText" component="ul">
          <li>
            <strong>Healing from Pain:</strong> recovering and building
            resilience after hardship
          </li>
          <li>
            <strong>Finding Supportive Communities:</strong> learning to go
            beyond silence and loneliness when facing hardship to build trust
            and connection, and to do this effectively for others
          </li>
          <li>
            <strong>Reimagining Inner Stories:</strong> grappling with raw inner
            identities, and getting inspired by the best at emotional alchemy
            and storytelling
          </li>
          <li>
            <strong>Create New Stories and Art:</strong> learning to find and
            develop a new story, and all the skills needed to express it
            creatively
          </li>
        </Typography>
        <Typography variant="gbText">
          GB Holders will have the chance to nominate and vote for specific
          artists and organizations to support.
        </Typography>
      </Grid>

      {/* Team */}
      <Grid container item columns={4} rowSpacing={6}>
        {/* Bottom Images */}
        <SampleImages position="bottom" />
      </Grid>
    </Grid>
  );
};

// Row of 4 sample GB images
const SampleImages: React.FC<{position: 'top' | 'bottom'}> = ({position}) => {
  const gbSampleImages = useMemo(
    () => ({
      top: [gbSample0, gbSample1, gbSample2, gbSample3],
      bottom: [gbSample4, gbSample5, gbSample6, gbSample7],
    }),
    []
  );

  return (
    <Grid
      container
      item
      justifyContent="center"
      spacing={3}
      sx={{lineHeight: 0}}
      columns={4}>
      {gbSampleImages[position].map((src, i) => (
        <Grid item key={i} xs={2} sm={1}>
          <img
            src={src}
            alt={`GB Placeholder ${i}`}
            style={{
              width: '100%',
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

// GlitchyBitches artist statement + team members
const TeamMembers: React.FC = () => {
  const sx = useTheme().gbComponents.HomePage;

  const {
    name: gbName,
    src: gbSrc,
    contact: gbContact,
  } = teamMembers.glitchyBitches; // GlitchyBitches

  return (
    <>
      <Grid container item columns={3} justifyContent={{xs: 'center'}}>
        <Grid item sm={1} sx={sx.team.glitchyBitches}>
          <img src={gbSrc} alt={gbName} />{' '}
          <Box display="flex" flexDirection="column" sx={sx.team.caption}>
            <strong>{gbName}</strong>
            <Typography variant="gbText">
              Artist, designer, educator, <br />
              creator of The GBs
            </Typography>
            {gbContact}
          </Box>
        </Grid>
        <Grid item sm={2} sx={sx.team.artistStatement}>
          <Paper variant="gbPaper">
            <Typography variant="h5">
              <em>Hello!</em>
            </Typography>
            <Typography variant="gbText">
              <strong>
                Drawing the GBs was a process of using my imagination to create
                a more supportive world in a time of great need.{' '}
              </strong>
              In 2020, like so many other people, I lost people and things very
              dear to my heart. Inspired by artists like Hilma af Klint and
              Frida Kahlo who knew pain yet chose art, I wanted to turn
              suffering into something new and beautiful by making art from it.
              I drew the first few dozen GBs by hand, giving them everything I
              wish I had more of: strength, sisterhood, courage, hope,
              connection. After the art was done, I made changes in real life,
              too. I mustered the courage to speak up where I needed to, leave a
              stale job, and spend more time doing things that bring me joy.{' '}
            </Typography>
            <Typography variant={'gbText'}>
              <strong>The GBs have already given their gift to me.</strong> My
              hope is for The GBs to inspire holders to celebrate their
              multidimensional glitchy selves and, wherever needed, to find
              creative ways to rewrite and reframe their own difficulties as
              art.
            </Typography>

            <Box>
              <Typography variant="gbText">
                Love,
                <br />
                <strong>{gbName}</strong>
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        item
        columns={4}
        // justifyContent={{xs: 'center', sm: 'initial'}}
        textAlign={{xs: 'center', sm: 'initial'}}>
        <Grid
          item
          container
          direction="column"
          alignItems={{xs: 'center', sm: 'initial'}}>
          <Typography variant="h3" gutterBottom>
            Team
          </Typography>
          <Typography variant="gbText">
            <strong>{gbName}</strong> created the art and concept, and is
            supported by a top technical team.
          </Typography>
        </Grid>
        {(
          ['arran', 'harri', 'nathan', 'altay'] as Array<
            keyof typeof teamMembers
          >
        ).map((memberKey, i) => {
          const member = teamMembers[memberKey];
          return (
            <Grid key={i} item xs={4} sm={2} md={1} sx={sx.team.member}>
              <Avatar src={member.src} alt={member.name} />{' '}
              <Box display="flex" flexDirection="column" sx={sx.team.caption}>
                <strong>{member.name}</strong>
                <Typography variant="gbText">{member.role}</Typography>
                {member.contact}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

// // Dates for pre-registration, private sale, open minting. Displayed in pre-mint period.
// const MintSchedule: React.FC = () => {
//   return (
//     <Box textAlign="center">
//       {/* <Typography variant="gbText">
//         <span>Pre-register</span> for the private sale{' '}
//         <ExternalLink href={gbUrls.premint}>here</ExternalLink> before: <br />
//       </Typography>
//       <Typography variant="gbText2">
//         28 November <span>@</span> 3pm&nbsp;ET&nbsp;<span>/</span>
//         &nbsp;8pm&nbsp;GMT&nbsp;
//         <span>/</span>&nbsp;12pm&nbsp;PT
//       </Typography> */}
//       <Typography variant="gbText">
//         <span>Private sale</span> for pre-registered supporters begins: <br />
//       </Typography>
//       <Typography variant="gbText2">
//         <strong>TODAY!</strong> <span>@</span> 3pm&nbsp;ET&nbsp;<span>/</span>
//         &nbsp;8pm&nbsp;GMT&nbsp;
//         <span>/</span>&nbsp;12pm&nbsp;PT
//       </Typography>
//       <Typography variant="gbText">
//         <span>Open minting</span> for the public begins:
//       </Typography>
//       <Typography variant="gbText2" marginBottom={0}>
//         <strong>TOMORROW</strong> <span>@</span> 3pm&nbsp;ET&nbsp;<span>/</span>
//         &nbsp;8pm&nbsp;GMT&nbsp;
//         <span>/</span>&nbsp;12pm&nbsp;PT
//       </Typography>
//     </Box>
//   );
// };
