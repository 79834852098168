import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  DialogContentText,
  TextField,
  Tooltip,
  useTheme,
  Zoom,
} from '@mui/material';
import {useGbUser} from './GbUserContextProvider';
import {useGb, useGbContractMethod} from '../hooks/gbHooks';
import iconFireworks from '../assets/images/icon_fireworks_2x.png';
import {ButtonWithLoader} from './ButtonWithLoader';
import {REVEAL_VERSION_COST} from '../util/constants';

// The form used to send a Glitter Bomb
// TODO: use formik?
export const SendGlitterForm: React.FC<{
  gbId: number;
  onSuccess(): void;
}> = ({gbId, onSuccess}) => {
  const theme = useTheme();
  const sx = theme.gbComponents.MeetPage.GbCard.GbCardMetadata.Metapurse;

  const gbUser = useGbUser();
  const {gb} = useGb(gbId);

  const {
    method: donate,
    // status: donateStatus,
    loading,
    waiting,
    // error: sendGlitterError, // TODO: display errors
  } = useGbContractMethod('donate');

  // Amount of glitter to transfer
  const [transactionAmount, setTransactionAmount] = useState<number | null>(
    null
  );
  const updateTransactionAmount = useCallback((value: string) => {
    if (value === '') {
      setTransactionAmount(null);
      return;
    }
    const isNumber = /^\d+$/.test(value);
    if (!isNumber) {
      return;
    }
    let newValue = parseInt(value);
    if (newValue <= 0) {
      newValue = 0;
    }
    // TODO: set max amount
    setTransactionAmount(newValue);
  }, []);

  // ID of the GB that will receive the glitter
  const [recipientGbId, setRecipientGbId] = useState<number | null>(null);
  const updateRecipientGbId = useCallback((value: string) => {
    if (value === '') {
      setRecipientGbId(null);
      return;
    }
    const isNumber = /^\d+$/.test(value);
    if (!isNumber) {
      return;
    }
    let newValue = parseInt(value);
    // TODO: validation (does user own this gb or not?)
    setRecipientGbId(newValue);
  }, []);

  const submitSendGlitterForm = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (recipientGbId == null || transactionAmount == null) {
        // TODO: show error message
        return;
      }
      try {
        await donate({
          variables: [gbId, recipientGbId, transactionAmount],
          refetchGbIds: [gbId, recipientGbId],
        });
        onSuccess();
      } catch (e) {
        // TODO: better error handling
        console.log('submitSendGlitterForm error:', e);
        return false;
      }
    },
    [donate, gbId, onSuccess, recipientGbId, transactionAmount]
  );

  const glitterBalance = useMemo(
    () => gb?.data?.changeCredits,
    [gb?.data?.changeCredits]
  );

  // set initial value for transactionAmount based on glitterBalance
  useEffect(() => {
    if (glitterBalance != null) {
      setTransactionAmount(Math.min(REVEAL_VERSION_COST, glitterBalance));
    }
  }, [glitterBalance]);

  type ValidationField = keyof typeof validationTests;
  type ValidationResult = {
    valid: boolean;
    errorMessage: string;
    warningOnly?: boolean; // if true, failing validation will not disable the form
  };

  const validationTests = useMemo(
    () => ({
      amount: [
        {
          valid:
            transactionAmount == null ||
            glitterBalance == null ||
            transactionAmount <= glitterBalance, // validation passes if this is true
          errorMessage: `You only have ${glitterBalance} Glitter Credits`,
        },
        {
          valid: transactionAmount == null || transactionAmount > 0,
          errorMessage: 'You need to send at least 1 Glitter Credit',
        },
      ] as ValidationResult[],
      recipientGbId: [
        // [recipientGbId != null, 'Please specify a GB ID'],
        {
          valid:
            recipientGbId == null ||
            (gbUser.ownedGbIds != null &&
              gbUser.ownedGbIds.includes(recipientGbId)),
          errorMessage: 'You do not own this GB. Proceed with caution!',
          warningOnly: true,
        },
        {
          valid: recipientGbId !== gbId,
          errorMessage: `GB #${gbId} can't send Glitter to itself!`,
        },
      ] as ValidationResult[],
    }),
    [gbId, gbUser.ownedGbIds, glitterBalance, recipientGbId, transactionAmount]
  );

  const formErrors = useMemo(() => {
    const errors: {[key in ValidationField]?: string} = {};
    const warnings: {[key in ValidationField]?: string} = {};
    (Object.keys(validationTests) as ValidationField[]).forEach(key => {
      const firstFailingTest = validationTests[key].find(test => !test.valid);
      (firstFailingTest?.warningOnly ? warnings : errors)[key] =
        firstFailingTest == null ? undefined : firstFailingTest.errorMessage;
    });
    return {errors, warnings};
  }, [validationTests]);

  const isFormValid = useMemo(() => {
    return (
      recipientGbId != null &&
      Object.values(formErrors.errors).every(err => err == null)
    );
  }, [formErrors, recipientGbId]);

  return (
    <>
      <Box
        component="form"
        sx={sx.dialogMetapurse.form}
        noValidate
        onSubmit={submitSendGlitterForm}
        autoComplete="off">
        {/*<Typography variant="h5" sx={{color: 'white'}}>*/}
        {/*  Glitter Bomb*/}
        {/*</Typography>*/}
        <Box
          display="flex"
          flexDirection={{xs: 'column', md: 'row'}}
          rowGap={'1.2rem'}>
          <Box display="flex" alignItems={'center'} justifyContent={'center'}>
            <DialogContentText>
              Send
              <Tooltip
                open={
                  formErrors.errors.amount != null ||
                  formErrors.warnings.amount != null
                }
                arrow
                placement={'bottom'}
                title={
                  formErrors.errors.amount ?? formErrors.warnings.amount ?? ''
                }>
                {/* TODO: you can type letters in here. :-| */}
                <TextField
                  size="small"
                  label="Amount"
                  variant="outlined"
                  type="number"
                  autoFocus
                  value={transactionAmount ?? ''}
                  onChange={e => updateTransactionAmount(e.target.value)}
                  error={
                    !(
                      formErrors.errors.amount == null ||
                      formErrors.warnings.amount == null
                    )
                  }
                  // helperText={formErrors.amount}
                />
              </Tooltip>
              credit{transactionAmount === 1 ? '' : 's'}&nbsp;
            </DialogContentText>
          </Box>
          <Box display="flex" alignItems={'center'} justifyContent={'center'}>
            <DialogContentText>to GB #</DialogContentText>
            <DialogContentText>
              <Tooltip
                open={
                  formErrors.errors.recipientGbId != null ||
                  formErrors.warnings.recipientGbId != null
                }
                arrow
                TransitionComponent={Zoom}
                placement={'bottom'}
                title={
                  formErrors.errors.recipientGbId ??
                  formErrors.warnings.recipientGbId ??
                  ''
                }>
                <TextField
                  size="small"
                  label="GB ID"
                  value={recipientGbId ?? ''}
                  onChange={e => updateRecipientGbId(e.target.value)}
                  variant="outlined"
                  // placeholder="Enter GB ID"
                  error={
                    !(
                      formErrors.errors.recipientGbId == null ||
                      formErrors.warnings.recipientGbId == null
                    )
                  }
                  // helperText={formErrors.recipientGbId}
                />
              </Tooltip>
            </DialogContentText>
          </Box>
          <Box display="flex" alignItems={'center'} justifyContent={'center'}>
            <ButtonWithLoader
              type="submit"
              loading={loading || waiting}
              disabled={!isFormValid || loading || waiting}
              variant="contained"
              color="secondary"
              sx={sx.glitterIconButton}>
              <img src={iconFireworks} alt="Glitter!" />
              {loading ? (
                <>
                  Confirm
                  <Box sx={{display: {xs: 'none', md: 'revert'}}}>
                    &nbsp;in Metamask
                  </Box>
                  ...
                </>
              ) : waiting ? (
                'Sending Glitter...'
              ) : (
                'Send Glitter!'
              )}
            </ButtonWithLoader>
          </Box>
        </Box>
      </Box>
    </>
  );
};
