import {Box, Grid, Paper, useTheme} from '@mui/material';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useGbData} from './GbDataContextProvider';
import {GbCardMetadata} from './GbCardMetadata';
import {GbCardCarousel} from './GbCardCarousel';
import {GbCardAttributes} from './GbCardAttributes';

export const GbCard: React.FC<{
  gbId: number;
}> = ({gbId}) => {
  const theme = useTheme();
  const sx = theme.gbComponents.MeetPage.GbCard;
  const {findGbById, fetchGbById} = useGbData();

  useEffect(() => {
    // Initial loading of GB data
    (async () => await fetchGbById(gbId))();
  }, [fetchGbById, gbId]);

  const gb = findGbById(gbId);

  // selectedVersion is the currently-selected image (not necessarily the live version)
  const [selectedVersion, setSelectedVersion] = useState(0);

  useLayoutEffect(() => {
    setSelectedVersion(gb?.data?.currentVersion || 0);
  }, [gb?.data?.currentVersion]);

  // console.log('render GbCard', gb.id, gb);
  return (
    <Grid container sx={sx.root}>
      <Paper variant="gbPaper" sx={{width: '100%', padding: 0}}>
        {gb == null ||
        (gb.loading && gb.data == null) || // refetching gb data
        gb.data?.currentVersion == null ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '2rem',
            }}>
            Loading...
          </Box>
        ) : (
          <>
            <GbCardMetadata gbId={gbId} />
            <GbCardCarousel
              gbId={gbId}
              selectedVersion={selectedVersion}
              setSelectedVersion={setSelectedVersion}
            />
            <GbCardAttributes gbId={gbId} selectedVersion={selectedVersion} />
          </>
        )}
      </Paper>
    </Grid>
  );
};
