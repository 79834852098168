import React from 'react';
import {Grid, Typography, useTheme} from '@mui/material';
import {Metapurse} from './Metapurse';

export const GbCardMetadata: React.FC<{gbId: number}> = ({gbId}) => {
  const theme = useTheme();
  const sx = theme.gbComponents.MeetPage.GbCard.GbCardMetadata;

  return (
    <Grid container item columns={2} sx={sx.root}>
      <Grid item xs={1}>
        <Typography variant="h4" component="h3" sx={sx.gbId} gutterBottom>
          GB #{gbId}
        </Typography>
      </Grid>
      <Grid item xs={1} sx={{textAlign: 'right', position: 'relative'}}>
        <Metapurse gbId={gbId} />
      </Grid>
    </Grid>
  );
};
