import {responsiveFontSizes} from '@mui/material';
import createTheme, {ThemeOptions} from '@mui/material/styles/createTheme';
import {SxProps} from '@mui/system';
import gbLogo from '../assets/images/logo_gb_2x.png';
import questionMark from '../assets/images/question_mark.png';

type GBThemeColor =
  | 'white'
  | 'whiteDark'
  | 'black'
  | 'blackLight'
  | 'blackLighter'
  | 'gray'
  | 'grayLight'
  | 'grayLighter'
  | 'grayDark'
  | 'teal'
  | 'tealLight'
  | 'tealDark'
  // | 'blue'
  | 'blueNavy'
  | 'pink'
  | 'pinkLight'
  | 'pinkDark'
  // | 'tan'
  // | 'tanLight'
  // | 'tanDark'
  | 'green'
  | 'greenLight'
  | 'greenDark';

export const gbThemeColors: {[key in GBThemeColor]: string} = {
  white: '#ffffff',
  whiteDark: '#ffffffC4', // 8-digit hex, last two digits are opacity 77%
  black: '#000000',
  blackLight: '#030D14',
  blackLighter: '#0B1B27',
  gray: '#979797',
  grayLight: '#B7B7B7',
  grayLighter: '#D8D8D8',
  grayDark: '#414040',
  teal: '#02AAB0',
  tealLight: '#5bdce2',
  tealDark: '#007a81',
  // blue: '#2F00EE',
  blueNavy: '#183B56',
  pink: '#F2148B',
  pinkLight: '#FF82BC',
  pinkDark: '#B2085F',
  // tan: '#E7BF9F',
  // tanLight: '#FFF2D0',
  // tanDark: '#B48F70',
  green: '#7EA771',
  greenLight: '#AED9A0',
  greenDark: '#507845',
};

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    gbGradient: true;
  }
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    gbAttr: true;
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    gbPaper: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    gbText: true;
    gbText2: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: {
      main: string;
      light: string;
      dark: string;
    };
  }
  interface PaletteOptions {
    tertiary?: {
      main?: string;
      light?: string;
      dark?: string;
    };
  }

  interface Theme {
    gbComponents: {
      AppBar: {
        root: SxProps<Theme>;
        logo: SxProps<Theme>;
        Tab: SxProps<Theme>;
        hamburger: SxProps<Theme>;
      };
      Drawer: {
        root: SxProps<Theme>;
      };
      buttonGbGradient: {
        waiting: SxProps<Theme>;
      };
      Footer: {
        root: SxProps<Theme>;
        links: SxProps<Theme>;
      };
      MintInfo: {
        root: SxProps<Theme>;
      };
      HomePage: {
        header: SxProps<Theme>;
        ticket: SxProps<Theme>;
        getYourGb: SxProps<Theme>;
        aboutTheGbs: {
          root: SxProps<Theme>;
          video: SxProps<Theme>;
        };
        philanthropy: SxProps<Theme>;
        team: {
          caption: SxProps<Theme>;
          glitchyBitches: SxProps<Theme>;
          artistStatement: SxProps<Theme>;
          member: SxProps<Theme>;
        };
        MintControls: {
          root: SxProps<Theme>;
          metamaskCheckout: SxProps<Theme>;
          counter: {
            root: SxProps<Theme>;
            button: SxProps<Theme>; // circular + and - IconButtons used for minting
          };
          mintButton: SxProps<Theme>;
          text: SxProps<Theme>;
        };
      };
      MeetPage: {
        // Section showing GB variants where you can select the live version
        GbCard: {
          root: SxProps<Theme>;
          GbCardMetadata: {
            root: SxProps<Theme>;
            gbId: SxProps<Theme>; // gb id listed on card
            Metapurse: {
              glitterIconButton: SxProps<Theme>; // Button with a glitter/fireworks icon
              // glitter bomb dialog that pops up when you click the 'metapurse' button
              dialogMetapurse: {
                root: SxProps<Theme>;
                form: SxProps<Theme>;
              };
            };
          };
          GBCardAttributes: {
            root: SxProps<Theme>;
            attributeChip: SxProps<Theme>; // tag that diplays attribute metadata
          };
          GbCardCarousel: {
            root: SxProps<Theme>;
            // Container for a single image and "Live version" text
            GbCardCarouselImage: {
              root: SxProps<Theme>;
              imageContainer: SxProps<Theme>; // Variable-size container for a single image, includes squiggle
              squiggle: SxProps<Theme>; // Color squiggle image behind selected image
              imageBorder: SxProps<Theme>; // Fixed-size border for a single image
              cardFace: SxProps<Theme>; // The front and back <Box> of the image card
              cardFaceBack: SxProps<Theme>; // The back <Box> of the image card (GB logo)
              caption: {
                root: SxProps<Theme>;
                button: SxProps<Theme>;
                buttonWaiting: SxProps<Theme>;
                error: SxProps<Theme>;
              }; // Button to change version
              noImage: SxProps<Theme>; // Question mark placeholder for unrevealed GB
              // TODO: figure out best practices for style composition
              selected: {
                // root: SxProps<Theme>;
                imageContainer: SxProps<Theme>;
                squiggle: SxProps<Theme>;
                imageBorder: SxProps<Theme>;
                // noImage: SxProps<Theme>;
                // cardFace: SxProps<Theme>;
              };
              live: {
                root: SxProps<Theme>;
                imageBorder: SxProps<Theme>;
                // noImage: SxProps<Theme>;
              };
              spinning: {
                imageBorder: SxProps<Theme>;
              };
            };
          };
        };
      };
    };
  }

  // Allow configuration using `createTheme.` All keys should be optional (unlike in Theme interface above)
  interface ThemeOptions {
    gbComponents?: {
      AppBar?: {
        root?: SxProps<Theme>;
        logo?: SxProps<Theme>;
        Tab?: SxProps<Theme>;
        hamburger?: SxProps<Theme>;
      };
      Drawer?: {
        root?: SxProps<Theme>;
      };
      buttonGbGradient?: {
        waiting?: SxProps<Theme>;
      };
      Footer?: {
        root?: SxProps<Theme>;
        links?: SxProps<Theme>;
      };
      MintInfo?: {
        root?: SxProps<Theme>;
      };
      HomePage?: {
        header?: SxProps<Theme>;
        ticket?: SxProps<Theme>;
        getYourGb?: SxProps<Theme>;
        aboutTheGbs?: {
          root?: SxProps<Theme>;
          video?: SxProps<Theme>;
        };
        philanthropy?: SxProps<Theme>;
        team?: {
          caption?: SxProps<Theme>;
          glitchyBitches?: SxProps<Theme>;
          artistStatement?: SxProps<Theme>;
          member?: SxProps<Theme>;
        };
        MintControls: {
          root?: SxProps<Theme>;
          metamaskCheckout?: SxProps<Theme>;
          counter?: {
            root?: SxProps<Theme>;
            button?: SxProps<Theme>; // circular + and - IconButtons used for minting
          };
          mintButton?: SxProps<Theme>;
          text?: SxProps<Theme>;
        };
      };
      MeetPage?: {
        GbCard?: {
          root?: SxProps<Theme>;
          GbCardMetadata?: {
            root?: SxProps<Theme>;
            gbId?: SxProps<Theme>;
            Metadata?: {
              glitterIconButton?: SxProps<Theme>;
              dialogMetapurse?: {
                root?: SxProps<Theme>;
                form?: SxProps<Theme>;
              };
            };
          };
          GBCardAttributes?: {
            root?: SxProps<Theme>;
            attributeChip?: SxProps<Theme>; // tag that diplays attribute metadata
          };
          GbCardCarousel?: {
            root?: SxProps<Theme>;
            GbCardCarouselImage?: {
              root?: SxProps<Theme>;
              imageContainer?: SxProps<Theme>; // Variable-size container for a single image, includes squiggle
              squiggle?: SxProps<Theme>; // Color squiggle image behind selected image
              imageBorder?: SxProps<Theme>;
              cardFace?: SxProps<Theme>;
              cardFaceBack?: SxProps<Theme>;
              noImage?: SxProps<Theme>;
              caption?: {
                root?: SxProps<Theme>;
                button?: SxProps<Theme>;
                buttonWaiting?: SxProps<Theme>;
                error?: SxProps<Theme>;
              }; // Button to change version
              selected?: {
                // root?: SxProps<Theme>;
                imageContainer?: SxProps<Theme>;
                squiggle?: SxProps<Theme>;
                imageBorder?: SxProps<Theme>;
                // cardFace?: SxProps<Theme>;
                // noImage?: SxProps<Theme>;
              };
              live?: {
                root?: SxProps<Theme>;
                imageBorder?: SxProps<Theme>;
                noImage?: SxProps<Theme>;
              };
              spinning?: {
                imageBorder?: SxProps<Theme>;
              };
            };
          };
        };
      };
    };
  }
}

const transitionTime = 0.1; // TODO: figure out where to put constants

// Annoying to have to do this, but we need it to refer to theme defaults in gbMuiThemeShared
const gbMuiThemeBase = createTheme();
const {spacing} = gbMuiThemeBase;

// These styles are applied to both the light and dark themes.
// Generally, everything except colors belongs in here.
const gbMuiThemeShared = createTheme(gbMuiThemeBase, {
  typography: {
    allVariants: {
      // MUI bug, I think... allVariants keys don't work in camelCase!
      // so we do 'font-family' instead of fontFamily
      'font-family': '"Montserrat", "Roboto","Helvetica","Arial",sans-serif',
      // fontFamily: '"Montserrat", "Roboto","Helvetica","Arial",sans-serif',
      'letter-spacing': '0.05em',
      '& ol, & ul': {
        paddingLeft: spacing(3),
      },
    },
    h3: {
      'text-align': 'left',
    },
    h6: {
      marginTop: spacing(6),
      marginBottom: spacing(2),
    },
  },
  // MUI built-in components.
  // Note: Don't put custom components here, use theme.gbComponents instead.
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          bottom: '1rem',
          height: '1px',
          display: 'flex',
          backgroundColor: 'transparent',
          justifyContent: 'center',
          '& > span': {
            width: '100%',
            marginLeft: '16px',
            marginRight: '16px',
            backgroundColor: gbThemeColors.pink,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '500px',
          fontSize: '1rem',
          lineHeight: '1em',
          padding: '0.5em 1em',
          textTransform: 'none',
          whiteSpace: 'nowrap',
        },
      },
    },
  },
  // Custom component styling.
  // Naming convention: If a key is capitalized, it refers to a top-level component function.
  // Uncapitalized keys are handles to elements in components (essentially classnames).
  // Not sure if this is useful but I'll try it for a bit...
  gbComponents: {
    AppBar: {
      root: {
        zIndex: gbMuiThemeBase.zIndex.drawer + 1, // make sure AppBar covers Drawer
        '& .MuiToolbar-root': {
          maxWidth: gbMuiThemeBase.breakpoints.values.lg,
          width: '100%',
          margin: '0 auto',
        },
      },
      logo: {
        '& a': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70px',
          '& img': {
            height: '60px',
            width: '60px',
          },
        },
      },
      Tab: {
        height: '70px',
        fontWeight: 'normal',
        fontSize: '0.8rem',
        opacity: 1,
        letterSpacing: '3px',
        '& .MuiBox-root': {
          display: 'flex',
          alignItems: 'center',
          '& img': {
            marginRight: '0.7rem',
            opacity: 0.4,
            width: '1rem',
          },
        },
      },
      hamburger: {
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    Drawer: {
      root: {
        width: '250px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {width: '250px', boxSizing: 'border-box'},
        // twitter + discord logos
        '& .MuiListItemText-root .MuiBox-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& img': {
            marginRight: '0.5rem',
            height: '1rem',
            width: '1rem',
          },
        },
      },
    },
    Footer: {
      root: {
        paddingBottom: spacing(2),
        // twitter + discord logos
        '& img': {
          marginBottom: '4px',
          height: '48px',
          width: '48px',
          '&:hover': {
            opacity: 0.8,
          },
        },
        '& a:first-of-type img': {
          marginRight: '12px',
        },
        '& a': {
          textDecoration: 'none',
        },
        '& .MuiTypography-caption': {display: 'block'},
        textAlign: {
          xs: 'center',
          md: 'initial',
        },
      },
      links: {
        justifyContent: {xs: 'center'},
        alignItems: {xs: 'center', md: 'flex-end'},
        '& hr': {
          background: gbThemeColors.gray,
        },
        '& a': {
          display: 'block',
          padding: {xs: '0 2em', md: 'initial'}, // hack! why isn't stack.spacing working??
        },
      },
    },
    buttonGbGradient: {
      waiting: {
        animation: 'bg-slide 2s linear infinite',
        '&:disabled': {
          opacity: 1,
        },
      },
    },
    MintInfo: {
      root: {
        padding: '20px',
        letterSpacing: '2px',
        borderRadius: spacing(2),
        maxWidth: '400px',
      },
    },
    HomePage: {
      header: {
        h6: {
          marginTop: spacing(2),
        },
        a: {
          color: gbThemeColors.pink,
          textDecoration: 'underline',
        },
      },
      ticket: {
        a: {
          color: gbThemeColors.pink,
          textDecoration: 'underline',
        },
      },
      getYourGb: {
        textAlign: {xs: 'center', md: 'initial'},
        '& .MuiTypography-gbText': {
          marginBottom: spacing(1),
          marginTop: spacing(4),
          fontSize: '1.2rem',
        },
        '& .MuiTypography-gbText2': {
          fontSize: {xs: '1.2rem', sm: '1.5rem'},
        },
      },
      aboutTheGbs: {
        root: {
          '& h3': {
            marginBottom: spacing(4),
          },
          '& h5': {
            marginBottom: spacing(1),
          },
          '& p': {
            marginBottom: spacing(4),
            '&:last-child': {
              marginBottom: 0,
            },
          },
        },
        video: {
          padding: '177.78% 0 0 0',
          position: 'relative',
          '& iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          },
        },
      },
      philanthropy: {
        // '& h4': {marginBottom: gbMuiThemeBase.spacing(4)},
        '& ul': {
          paddingLeft: spacing(6),
          marginBottom: spacing(4),
          marginTop: spacing(2),
        },
        '& p': {
          marginBottom: spacing(2),
        },
      },
      team: {
        // caption under image
        caption: {
          marginBottom: {xs: spacing(4)},
          strong: {
            fontSize: '1.5rem',
            marginTop: spacing(1),
          },
          '& .MuiTypography-gbText': {
            margin: 0,
          },
        },
        glitchyBitches: {
          flexDirection: 'column',
          position: 'relative',
          paddingTop: {sm: spacing(10)},
          textAlign: 'center',
          '& img': {
            position: 'relative',
            borderRadius: spacing(4),
            width: {xs: '100%', sm: '80%'},
          },
          // thought bubbles
          '&:before, &:after': {
            content: "''",
            position: 'absolute',
            display: {xs: 'none', sm: 'initial'},
            opacity: 0.2,
          },
          // big thought bubble
          '&:after': {
            top: spacing(3),
            right: spacing(3),
            width: '30px',
            height: '30px',
            borderRadius: '30px',
          },
          // little thought bubble
          '&:before': {
            top: spacing(6),
            right: spacing(9),
            width: '15px',
            height: '15px',
            borderRadius: '15px',
          },
        },
        artistStatement: {
          position: 'relative',
          '& h5': {
            marginBottom: spacing(2),
          },
          '& p': {
            // TODO: move color below
            color: gbThemeColors.white,
            fontSize: '12px',
            lineHeight: '1.4rem',
            marginBottom: spacing(2),
          },
          '& .MuiPaper-gbPaper': {
            padding: spacing(3),
            '& p:last-child': {
              marginBottom: '0',
            },
          },
          // signature
          '& .MuiBox-root ': {
            display: 'flex',
            justifyContent: 'right',
            marginRight: '10%',
            '& .MuiTypography-gbText': {
              fontSize: '1.5rem',
              lineHeight: '2rem',
            },
          },
        },
        member: {
          marginTop: spacing(2),
          textAlign: 'center',
          '& .MuiAvatar-root': {
            margin: '0 auto',
            width: '200px',
            height: '200px',
          },
          // team image caption
          '& .MuiBox-root': {
            '& .MuiTypography-gbText': {
              marginBottom: 0,
            },
          },
        },
      },

      MintControls: {
        root: {
          // height: '420px',
          // minHeight: '420px',
          boxSizing: 'border-box',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        },
        metamaskCheckout: {
          '& .MuiBox-root': {
            borderRadius: spacing(2),
            display: 'flex',
            justifyContent: 'center',
            marginTop: spacing(2),
          },
        },
        text: {
          fontSize: '1.5rem',
          '& span': {
            whiteSpace: 'nowrap',
          },
        },
        counter: {
          root: {
            fontSize: '3rem',
            lineHeight: '1.2rem',
            borderRadius: '500px',
            width: '220px',
            padding: '0.5rem 1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            msUserSelect: 'none', // Internet Explorer/Edge
            userSelect: 'none',
          },
          button: {
            padding: '0.0rem',
            '& svg': {
              fontSize: '3rem',
            },
          },
        },
        mintButton: {
          fontSize: '3rem',
          width: '220px',
          padding: '1rem',
          fontWeight: 'bold',
        },
      },
    },
    MeetPage: {
      GbCard: {
        root: {
          margin: '3rem 0',
        },
        GbCardMetadata: {
          root: {
            padding: {xs: spacing(2), md: spacing(4)},
            paddingBottom: {xs: 0, md: 0},
            color: gbThemeColors.whiteDark,
          },
          gbId: {
            fontWeight: 'bold',
            letterSpacing: '4px',
            margin: 0,
          },
          Metapurse: {
            glitterIconButton: {
              fontSize: {md: '1.2rem'},
              padding: '0.2em 1em',
              margin: 0,
              '& img': {
                marginRight: '8px',
                width: '32px',
              },
            },
            dialogMetapurse: {
              form: {
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: spacing(4),
                '&> .MuiBox-root': {
                  margin: '0 auto',
                },
                '& .MuiDialogContentText-root': {
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: {md: '1.4rem'},
                },
                '& .MuiTextField-root': {
                  width: '6rem',
                  margin: '0 0.5rem',
                },
                '& .MuiOutlinedInput-input': {
                  fontSize: {md: '1.4rem'},
                  fontWeight: 'bold',
                  borderRadius: '4px',
                },
                '& .MuiInputLabel-root': {
                  marginLeft: '-5px',
                  borderRadius: '4px',
                  padding: '0px 10px',
                  textShadow: 'none',
                },
                button: {
                  margin: {xs: `${spacing(2)} 0`, md: `0 0 0 ${spacing(2)}`},
                  '&:disabled': {
                    opacity: 0.8,
                  },
                },
              },
            },
          },
        },
        GBCardAttributes: {
          root: {
            overflow: 'auto',
            padding: {xs: '1rem', md: '2rem'},
            paddingTop: {xs: '0rem', md: '0rem'},
            '& h6': {marginTop: 0},
          },
          attributeChip: {
            fontFamily: '"Montserrat", "Roboto","Helvetica","Arial",sans-serif',
            letterSpacing: 0,
            fontWeight: 'bold',
            fontSize: '12px',
            marginRight: '1em',
            marginBottom: '1em',
            padding: '0',
            height: '2.2em',
            // animation: '0.5s linear fadeIn', // TODO: animate?
          },
        },
        GbCardCarousel: {
          root: {
            overflow: 'auto',
            justifyContent: {md: 'center'},
          },
          GbCardCarouselImage: {
            root: {
              margin: '0 15px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
              transition: `margin-top ${transitionTime}s, color ${transitionTime}s`, // TODO: use transform instead?
              height: '400px',
              position: 'relative',
            },
            imageContainer: {
              textAlign: 'center',
              transition: `0.1s linear`,
              width: '90px',
              padding: 0,
              perspective: '750px',
            },
            squiggle: {
              width: '90px',
              position: 'absolute',
              left: '0',
              right: '0',
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
              display: 'block',
              zIndex: 1,
              transition: '0.1s ease-in',
              opacity: 0,
            },
            imageBorder: {
              transformStyle: 'preserve-3d',
              // borderRadius: spacing(1),
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '90px',
              height: '126px',
              margin: '0 auto',
              transition: `${transitionTime}s linear`,
              zIndex: 2,
              position: 'relative',
            },
            cardFace: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              height: '100%',
              width: '100%',
              backfaceVisibility: 'hidden',
              '& img': {
                height: '100%',
                width: '100%',
                transition: `${transitionTime}s linear`,
              },
            },
            cardFaceBack: {
              transform: 'rotateY(180deg)',
              background: `url('${gbLogo}') center no-repeat`,
              backgroundSize: 'contain',
            },
            // Placeholder (question mark) for unrevealed GBs
            noImage: {
              background: `url('${questionMark}') center no-repeat`,
              backgroundSize: 'contain',
              // TODO: border radius?
              // borderRadius: spacing(1),
            },
            // Button to change version, plus message
            caption: {
              root: {
                height: '100px',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '1.5rem',
                alignItems: 'center',
              },
              button: {
                fontSize: '1.1rem',
                marginBottom: '0.5rem',
                '&:hover ': {
                  fontWeight: 'bold',
                },
              },
              buttonWaiting: {
                animation: 'bg-slide 2s linear infinite',
                '&:disabled': {
                  opacity: 1,
                },
              },
            },
            selected: {
              imageContainer: {
                position: 'relative',
                width: '260px',
                padding: '20px',
                // transition: "1s ease-in",
              },
              squiggle: {
                width: '260px',
                // transition: "0.1s ease-in",
                top: '0px',
                opacity: 0.7,
              },
              imageBorder: {
                width: '165px',
                height: '231px',
                // transition: `${transitionTime}s linear`,
                boxShadow: gbMuiThemeBase.shadows[12],
              },
            },
            spinning: {
              imageBorder: {
                position: 'relative',
                // Card spin/flip effect
                '@keyframes card-spin': {
                  from: {
                    transform: 'rotateY(0deg)',
                  },
                  to: {
                    transform: 'rotateY(360deg)',
                  },
                },
                animation: 'card-spin 1.8s linear infinite',
              },
            },
          },
        },
      },
    },
  },
} as ThemeOptions);

// TODO: create light theme?
export const gbMuiThemeLight = responsiveFontSizes(
  createTheme(gbMuiThemeShared, {
    palette: {
      mode: 'light',
      background: {
        // default: '#e4f0e2',
      },
    },
  } as ThemeOptions)
);

// All color-related rules should go in here. (Layout rules go in gbMuiThemeShared)
export const gbMuiThemeDark = responsiveFontSizes(
  createTheme(gbMuiThemeShared, {
    palette: {
      mode: 'dark',
      background: {
        default: gbThemeColors.blackLighter,
      },
      text: {
        primary: gbThemeColors.white,
        secondary: gbThemeColors.grayLight,
      },
      primary: {
        main: gbThemeColors.pink,
        // NOTE: primary.light and primary.dark are not at all related to light/dark mode!
        // They're just slight variants of primary.main, used for hovers and whatnot.
        // This tripped me up when trying to grok MUI.
        light: gbThemeColors.pinkLight,
        dark: gbThemeColors.pinkDark,
      },
      // TODO: why does augmentColor produce different (worse) colors than https://material.io/resources/color/ ?
      // primary: gbMuiThemeBase.palette.augmentColor({
      //   color: {main: gbThemeColors.pink},
      // }),
      // secondary: {
      //   main: gbThemeColors.tan,
      //   light: gbThemeColors.tanLight,
      //   dark: gbThemeColors.tanDark,
      // },
      secondary: {
        main: gbThemeColors.teal,
        light: gbThemeColors.tealLight,
        dark: gbThemeColors.tealDark,
      },
    },

    typography: {
      h2: {
        color: gbThemeColors.pink,
        // TODO: enable text shadow on this and h3?
        // textShadow: `1px 1px 2px ${gbThemeColors.gray}`,
      },
      h3: {
        // textShadow: `1px 1px 2px ${gbThemeColors.gray}`,
        color: gbThemeColors.tealLight,
      },
      h6: {
        color: gbThemeColors.whiteDark,
      },
    },

    // Custom components
    gbComponents: {
      AppBar: {
        root: {
          background: gbThemeColors.blackLight,
          color: gbThemeColors.white,
        },
        hamburger: {
          '& svg': {
            color: gbThemeColors.white,
          },
        },
        Tab: {
          color: gbThemeColors.white,
          transition: `color ${transitionTime}s`,
          '&:hover': {
            color: gbThemeColors.pink,
          },
          '& .MuiTouchRipple-root': {
            color: gbThemeColors.pinkLight,
          },
        },
      },
      Footer: {
        // root: {
        //   background: gbThemeColors.teal,
        // },
        links: {
          '& a': {
            color: gbThemeColors.white,
          },
        },
      },
      MintInfo: {
        root: {
          color: gbThemeColors.blackLighter,
          background: gbThemeColors.grayLight,
          border: `1px solid ${gbThemeColors.teal}`,
          '& a': {
            color: gbThemeColors.pink,
          },
        },
      },
      HomePage: {
        getYourGb: {
          '& .MuiTypography-gbText': {
            color: gbThemeColors.grayLight,
            '& span': {
              color: gbThemeColors.tealLight,
            },
          },
        },
        team: {
          glitchyBitches: {
            '& img': {
              border: `1px solid ${gbThemeColors.teal}`,
              boxShadow: `0px 0px 6px 1px ${gbThemeColors.teal}`,
            },
            // thought bubbles
            '&:before, &:after': {
              background: gbThemeColors.blueNavy,
              border: `1px solid ${gbThemeColors.teal}`,
              boxShadow: `0px 0px 3px 3px ${gbThemeColors.teal}`,
            },
          },
          artistStatement: {
            '& p': {
              color: gbThemeColors.white,
            },
          },
        },
        MintControls: {
          metamaskCheckout: {
            color: gbThemeColors.gray,
            '& .MuiBox-root': {
              background: gbThemeColors.grayLight,
            },
          },
          mintButton: {
            color: gbThemeColors.blackLighter,
            boxShadow: gbMuiThemeBase.shadows[10],
          },
          text: {
            '& a': {
              color: gbThemeColors.pink,
            },
          },
          counter: {
            root: {
              background: gbThemeColors.grayLight,
              border: `1px solid ${gbThemeColors.teal}`,
              color: gbThemeColors.blackLighter,
              fontWeight: 'bold',
            },
            // Circular + and - IconButtons used to change mint count
            button: {
              // TODO: box shadow to make it more pressable?
              boxShadow: gbMuiThemeBase.shadows[10],
              // boxShadow: `0px 0px 3px 1px ${gbThemeColors.white}`,
              // border: `1px solid ${gbThemeColors.gray}`,
              color: gbThemeColors.blackLighter,
              background: gbThemeColors.pink,
              '&:hover': {
                background: gbThemeColors.pinkDark,
              },
              '&.Mui-disabled': {
                border: 0,
                // opacity: 0.5,
                boxShadow: 0,
                background: gbThemeColors.gray,
              },
            },
          },
        },
      },
      MeetPage: {
        GbCard: {
          GbCardMetadata: {
            Metapurse: {
              glitterIconButton: {
                color: gbThemeColors.whiteDark,
              },
              dialogMetapurse: {
                root: {
                  h3: {
                    color: gbThemeColors.pink,
                  },
                  '& .MuiTypography-body1': {
                    color: gbThemeColors.blackLight,
                  },
                },
                '& .MuiTypography-subtitle2': {
                  color: gbThemeColors.gray,
                },
                form: {
                  background: gbThemeColors.pink,
                  '& .MuiDialogContentText-root': {
                    color: gbThemeColors.white,
                    textShadow: '0px 2px 2px rgba(0,0,0,0.5)',
                    marginBottom: {xs: 0, md: 'revert'},
                  },
                  '& .MuiOutlinedInput-input': {
                    background: gbThemeColors.white,
                    color: gbThemeColors.grayDark,
                  },
                  '& .MuiOutlinedInput-root:hover fieldset': {
                    borderColor: gbThemeColors.pinkDark,
                  },
                  '& .MuiInputLabel-root': {
                    color: gbThemeColors.pinkDark,
                    background: gbThemeColors.white,
                  },
                  fieldset: {
                    borderColor: gbThemeColors.pinkDark,
                  },
                  input: {
                    color: gbThemeColors.gray,
                  },
                  button: {
                    color: gbThemeColors.white,
                    '&:disabled': {
                      color: gbThemeColors.whiteDark,
                      background: gbThemeColors.teal,
                    },
                  },
                },
              },
            },
          },
          GBCardAttributes: {
            attributeChip: {
              background: gbThemeColors.white,
              color: gbThemeColors.gray,
              border: `1px solid ${gbThemeColors.pink}`,
              '& strong': {
                color: gbThemeColors.grayDark,
              },
            },
          },
          GbCardCarousel: {
            GbCardCarouselImage: {
              root: {
                color: gbThemeColors.blueNavy,
              },
              cardFaceBack: {
                backgroundColor: gbThemeColors.grayLighter,
              },
              noImage: {
                backgroundColor: gbThemeColors.grayLighter,
              },
              caption: {
                root: {
                  '& .MuiTypography-root': {
                    color: gbThemeColors.grayLighter,
                  },
                },
                error: {
                  '&.MuiTypography-body2': {
                    color: gbThemeColors.pinkLight,
                  },
                },
              },
              live: {
                root: {
                  color: gbThemeColors.teal,
                },
                imageBorder: {
                  border: `5px solid ${gbThemeColors.teal}`,
                },
              },
            },
          },
        },
      },
    },
    // MUI built-in components.
    // Note: Don't put custom components here, use theme.gbComponents instead.
    // Also note: Composition from multiple themes doesn't seem to work right,
    // so both layout+colors need to be in a single styleOverrides :-|
    components: {
      MuiDivider: {
        styleOverrides: {
          root: {
            marginTop: gbMuiThemeBase.spacing(6),
            marginBottom: gbMuiThemeBase.spacing(6),
            background: gbThemeColors.teal,
            opacity: 0.3,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            color: gbThemeColors.white,
            backgroundColor: gbThemeColors.blackLight,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            '& h4': {
              color: gbThemeColors.pink,
              // marginBottom: spacing(2),
            },
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            // Dialogs in design have white background, so change the default text back to black
            // TODO: create actual dark theme dialog?
            color: gbThemeColors.blackLight,
            margin: `${spacing(3)} 0`,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            color: gbThemeColors.grayDark,
          },
          tooltip: {
            background: gbThemeColors.grayDark,
            color: gbThemeColors.white,
            fontSize: '1em',
            padding: '0.5em 1em',
          },
        },
      },
      MuiPaper: {
        variants: [
          {
            props: {variant: 'gbPaper'},
            style: {
              backgroundColor: gbThemeColors.blueNavy,
              borderRadius: gbMuiThemeBase.spacing(2),
              padding: `${gbMuiThemeBase.spacing(4)} ${gbMuiThemeBase.spacing(
                6
              )}`,
              border: `1px solid ${gbThemeColors.teal}`,
              // boxShadow: `0px 0px 3px 3px ${gbThemeColors.teal}`,
              boxShadow: `0px 0px 6px 1px ${gbThemeColors.teal}`,
              // boxShadow: `0px 0px 6px 6px ${gbThemeColors.blue}`,
            },
          },
        ],
      },
      MuiTypography: {
        defaultProps: {variantMapping: {gbText: 'p', gbText2: 'p'}},
        variants: [
          {
            props: {variant: 'gbText'},
            style: {
              lineHeight: '1.6rem',
              color: gbThemeColors.whiteDark,
              marginBottom: gbMuiThemeBase.spacing(2),
            },
          },
          {
            props: {variant: 'gbText2'},
            style: {
              color: gbThemeColors.white,
              fontSize: {xs: '1rem', sm: '2.5rem'},
              '& span': {
                color: gbThemeColors.gray,
              },
              '& strong': {
                color: gbThemeColors.pink,
              },
            },
          },
        ],
      },
      MuiButton: {
        variants: [
          // 'Connect your wallet' gradient button in nav bar
          // TODO: clean this up. maybe sx instead of variant?
          {
            props: {variant: 'gbGradient'},
            style: {
              // color: gbThemeColors.white,
              '@keyframes bg-slide': {
                from: {backgroundPosition: '0% 0%'},
                to: {backgroundPosition: '-200% 0%'},
              },
              boxShadow: gbMuiThemeBase.shadows[4],
              backgroundColor: gbThemeColors.pink,
              backgroundImage: `linear-gradient(to right, ${gbThemeColors.teal}, ${gbThemeColors.pink}, ${gbThemeColors.teal})`,
              textTransform: 'uppercase',
              fontSize: '14px',
              color: gbThemeColors.grayLighter,
              textShadow: `1px 1px 3px ${gbThemeColors.grayDark}`,
              border: `1px solid ${gbThemeColors.gray}`,
              backgroundSize: '200% auto',
              backgroundPosition: '0 0',
              transition: 'background-position 0.5s, border-color 0.5s',
              whiteSpace: 'nowrap',
              '&:hover': {
                borderColor: gbThemeColors.pinkLight,
                backgroundPosition: '-200% 0',
                boxShadow: 'none',
              },
              '&:disabled': {
                boxShadow: 'none',
                color: gbThemeColors.grayLighter,
                opacity: 0.5,
              },
            },
          },
        ],
      },
    },
  } as ThemeOptions)
);

// TODO: just for testing, remove
// window.gbTheme = gbMuiThemeDark;
